import React from "react";
import * as THREE from "three";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {OrbitControls} from  "three/examples/jsm/controls/OrbitControls";
import { ReactComponent as CloseIcon } from '../assets/close.svg';
import { ReactComponent as MoveIcon } from '../assets/move.svg';
import { ReactComponent as ZoomIcon } from '../assets/zoom.svg';
import Translator from '../translator/translator';
import config from "../config";
import './preview.scss';

const translator = new Translator();

let map = [
    config.api + 'map/computer-history-museum/pos-x.jpg',
    config.api + 'map/computer-history-museum/neg-x.jpg',
    config.api + 'map/computer-history-museum/pos-y.jpg',
    config.api + 'map/computer-history-museum/neg-y.jpg',
    config.api + 'map/computer-history-museum/pos-z.jpg',
    config.api + 'map/computer-history-museum/neg-z.jpg',
];

const loader = new THREE.CubeTextureLoader();
const envMap = loader.load(map);

const loadModel = (url,name, fill)=>{
    return new Promise((resolve, reject)=>{
        const manager = new THREE.LoadingManager();
        const loader = new GLTFLoader(manager);
        loader.load(url,
            function (gltf) {
                gltf.scene.name = name;
                if(fill){
                    gltf.scene.userData = { fill: true}
                }
                resolve(gltf.scene)
            },
            function (xhr) {

            },
            function (error) {
                console.log(error);
            }
        );
    });
}

const setTexture = (model, preview_images, color, env)=>{
    let url = preview_images[model.name];
    if(url){
        let image = new Image();
        let texture = new THREE.Texture();
        texture.image = image;
        image.onload = function () {
            texture.needsUpdate = true;
        };
        image.src = url;
        texture.flipY = false;
        texture.repeat.set( 1, 1 );
        let material =  new THREE.MeshStandardMaterial({map: texture, side: THREE.FrontSide, envMap: envMap, transparent: true , envMapIntensity :env ? 1: 0.1 });
        model.traverse((child)=>{ 
            if(child.isMesh){
                child.material = material;
                child.material.needsUpdate = true;
            }
        });
    }
}

export default class Preview extends React.Component {

    constructor(props){
        super(props);
        this.surface = null;
        this.getImages = this.getImages.bind(this);
        this.pivot = new THREE.Object3D();
        this.scene = null;
        this.model = null;
        this.animate = this.animate.bind(this);
        this.state = { ready: false };
    }

    getImages(){

        let _this = this;

        return new Promise((resolve, reject)=>{
            
            let attempts = 0;

            let takeScreeenshotInterval = setInterval(()=>{
               
                if(attempts > 180){
                    clearInterval(takeScreeenshotInterval);
                    reject();
                }

                if(_this.state.ready){

                    clearInterval(takeScreeenshotInterval);

                    _this.pivot.rotation.y = Math.PI/ 2;

                    this.camera.position.set(100,0,0);
                    this.camera.lookAt(new THREE.Vector3(0,0,0))
    
                    _this.animate();
    
                    let front = _this.renderer.domElement.toDataURL();
        
        
                    setTimeout(()=>{
    
                         this.camera.position.set(-100,0,0);
    
                         this.camera.lookAt(new THREE.Vector3(0,0,0))
    
                         _this.animate();
    
                         let back = _this.renderer.domElement.toDataURL();

                        setTimeout(()=>{
    
                            this.camera.position.set(0,0,100);
       
                            this.camera.lookAt(new THREE.Vector3(0,0,))
       
                            _this.animate();
       
                            let middle = _this.renderer.domElement.toDataURL();
               
                            resolve( [front, back, middle]);
           
                       },1000);
        
                    },1000);
                }else{
                    attempts++;
                }
                
            },1000);
            
        });

    }

    changeModel(){

        let _this = this;

        if(_this.model){
            _this.pivot.remove(_this.model);
        }

        if(_this.surface){
            _this.surface.children = [];
        }

        let preview_images = this.props.preview_images;

        let product = this.props.product;

        loadModel(product.model).then((model)=>{

            _this.pivot.add(model);
            model.position.y -= (5);
            model.rotation.y = Math.PI/ 2;
            _this.model = model;

            let box = new THREE.Box3().setFromObject( model );
            let size = box.getSize(new THREE.Vector3());

            model.position.y = 0 - size.y/2;

            model.traverse((node) => {
                if (node.isMesh){
                    if(node.material && node.material.name === "dinamic_color") {
                        node.material.color = new THREE.Color(this.props.product.color);
                    }
                    node.material.envMap = envMap;
                    node.material.needsUpdate = true;
                    node.castShadow = true; 
                    node.receiveShadow = true;
                    node.material.envMapIntensity = product.env ? 1: 0.1;
                    if(node.material.map) node.material.map.anisotropy = 16; 
                }
            });

            let tasks = [];
            product.templates.forEach((t)=>{
                tasks.push(loadModel(t.model, t._id, t.fill))
            });
    
            Promise.all(tasks).then((models)=>{
                models.forEach((surfaceModel)=>{
                    const scale = 1.001;
                    surfaceModel.scale.set(scale,scale,scale)
                    _this.surface.add(surfaceModel);
                    surfaceModel.position.y = 0 - size.y/2;
                    surfaceModel.rotation.y = Math.PI/ 2;
                    surfaceModel.traverse((node) => {
                        if (node.isMesh){
                            if(surfaceModel.userData && surfaceModel.userData.fill) {
                                node.material.color = new THREE.Color(this.props.product.color);
                            }
                            node.material.envMap = envMap;
                            node.material.needsUpdate = true;
                            node.material.envMapIntensity = product.env ? 1: 0.1;
                            node.renderOrder = 0;
                        }
                    });
                    setTexture(surfaceModel, preview_images, this.props.product.color, product.env);
                });
                _this.setState({ ready: true });
            });

        });
        
    }

    componentDidMount() {

        let width = 800;
        let height = 500;

        var scene = new THREE.Scene();
        var camera = new THREE.PerspectiveCamera( 75, width / height, 0.1, 1000 );
        var renderer = new THREE.WebGLRenderer({antialias: true});
        renderer.setClearColor( 0xeeeeee, 1 );
        renderer.setSize( width,height );
        renderer.physicallyCorrectLights = true;
        renderer.setPixelRatio(1);

        this.renderer = renderer;
        this.mount.appendChild( renderer.domElement );

        if(this.props.product && this.props.product.light){
            const light = new THREE.AmbientLight( 0xffffff, 2);
            scene.add( light );
        }else{
            const light = new THREE.AmbientLight( 0xffffff, 1);
            scene.add( light );
        }

        const hemiLight = new THREE.HemisphereLight(0xffffff, 0x080820, 1);
        scene.add( hemiLight );

        const intencity = 0.5;

        camera.position.z = 105;
        camera.position.y = 0;
        this.camera = camera;

        let surface = new THREE.Group();
        this.pivot.add(surface);
        scene.add( this.pivot );
        this.surface = surface;

        const controls = new OrbitControls(camera,renderer.domElement);
        controls.minDistance = 10;
        controls.maxDistance = 200;
        controls.update();
        this.scene = scene;
        this.changeModel();

        this.animate();

    }

    animate() {
        requestAnimationFrame( this.animate );
        this.renderer.render( this.scene, this.camera );
    };
    
    render() {
        return (
            <div className="preview">
                <ul className="preview__list">
                    <li className="preview__item">
                        <div>
                            <MoveIcon></MoveIcon>
                        </div>
                        { translator.t('move_text') }
                    </li>
                    <li className="preview__item">
                        <div>
                            <ZoomIcon></ZoomIcon>
                        </div>
                        { translator.t('zoom_text') }
                    </li>
                </ul>
                <button className="close" onClick={this.props.onCloseClick}>
                    <CloseIcon></CloseIcon>
                </button>
                <div id="preview" ref={ref => (this.mount = ref)} />
            </div>
        )
    }
}