import React from 'react';
import Slider from 'react-rangeslider';
import { ReactComponent as MirrorHorisontalIcon } from '../../assets/mirror_hor.svg';
import { ReactComponent as MirrorVerticalIcon } from '../../assets/mirror_ver.svg';
import { ReactComponent as CenterHorisontalIcon } from '../../assets/center_horisontal.svg';
import { ReactComponent as CenterVerticalIcon } from '../../assets/center_vertical.svg';
import { ReactComponent as RevertIcon } from '../../assets/revert.svg';
import { ReactComponent as ArrowIcon } from '../../assets/arrow.svg';
import ImageAdd from "./imageAdd";
import 'react-rangeslider/lib/index.css'
import './images.scss';
import { SketchPicker } from 'react-color';
import {ReactComponent as CloseIcon} from "../../assets/close.svg";

export default class ImageControls extends React.Component{
    constructor(props){
        super(props);
        this.scaleChange = this.scaleChange.bind(this);
        this.angleChange = this.angleChange.bind(this);
        this.mirrorVertical = this.mirrorVertical.bind(this);
        this.mirrorHorisontal = this.mirrorHorisontal.bind(this);
        this.centerHorisontal = this.centerHorisontal.bind(this);
        this.centerVertical = this.centerVertical.bind(this);
        this.revertScale = this.revertScale.bind(this);
        this.revertAngle = this.revertAngle.bind(this);
        this.onOpenClick = this.onOpenClick.bind(this);
        this.state = { scale: 0, angle: 0 , open: true };
        this.onChangeColor = this.onChangeColor.bind(this);
    }

    onChangeColor(color){
        this.props.setTemplateColor(color.hex);
    };
    onOpenClick(){
        this.setState({ open: !this.state.open });
    }

    revertAngle(){
        let selected = this.props.selected;
        selected.set({ angle: 0 });
        this.props.canvas.renderAll();
        this.setState({ angle: 0 });
    }

    revertScale(){
        let selected = this.props.selected;
        selected.scaleToWidth(selected.width);
        selected.scaleToHeight(selected.height);
        selected.userScale = 1;
        this.props.canvas.renderAll();
        this.setState({ scale: 1 });
    }

    centerHorisontal(){
        let selected = this.props.selected;
        let template = this.props.template.clip;
        let left = template.left + (template.width / 2);
        selected.set("left",left)
        selected.setCoords();
        this.props.canvas.renderAll();
    }

    centerVertical(){
        let selected = this.props.selected;
        let template = this.props.template.clip;
        let top = template.top + (template.height / 2);
        selected.setCoords();
        selected.set("top",top);
        this.props.canvas.renderAll();
    }

    mirrorVertical(){
        let selected = this.props.selected;
        selected.flipY = (!selected.flipY) ? true: false;
        this.props.canvas.renderAll();
        this.props.canvasModifiedCallback();
    }
    mirrorHorisontal(){
        let selected = this.props.selected;
        selected.flipX = (!selected.flipX) ? true: false;
        this.props.canvas.renderAll();
        this.props.canvasModifiedCallback();
    }
    scaleChange (value){
        this.setState({ scale: value });
        let selected = this.props.selected;
        selected.scaleToWidth(selected.width * value);
        selected.scaleToHeight(selected.height * value);
        selected.userScale = value;
        this.props.canvas.renderAll();
        this.props.canvasModifiedCallback();
    }
    angleChange (value){
        this.setState({ angle: value });
        let selected = this.props.selected;
        selected.angle = value;
        this.props.canvas.renderAll();
        this.props.canvasModifiedCallback();
    }

    componentWillReceiveProps(nexProps){
        if((this.props.selected) && (nexProps.selected) && (this.props.selected !== nexProps.selected)){
            let scale = nexProps.selected.userScale;
            let angle = nexProps.selected.angle;
            this.setState({ scale: scale , angle : angle});
        }
    }
    componentDidMount(){
        if(this.props.selected){
            let scale = this.props.selected.userScale;
            let angle = this.props.selected.angle;
            this.setState({ scale: scale , angle : angle});
        }
    }
    render(){
        let { translator } = this.props;
        return(
            <div>
                {
                    this.props.product && ( (!this.props.selected) || (!this.props.selected.cacheKey)) &&
                    <div className="add_image_box">
                         <ImageAdd canvas={this.props.canvas} product={this.props.product} clip={this.props.clip}></ImageAdd>
                    </div>
                }
                {
                   this.props.selected && this.props.selected.cacheKey &&
                   <div>
                       {
                           this.state.open &&
                           <div className="image_controls">
                               {/* <div className="image_controls_close-box">
                                   <div>
                                       <button className="close-btn to-editor-btn close" onClick={this.onCloseProducts}><CloseIcon></CloseIcon></button>
                                   </div>
                               </div> */}
                                <div className="image_control_top">
                                    <div className="row">
                                        <div className="col-3">
                                            <button onClick={this.mirrorHorisontal}>
                                                <MirrorHorisontalIcon></MirrorHorisontalIcon>
                                            </button>
                                        </div>
                                        <div className="col-3">
                                            <button onClick={this.mirrorVertical}>
                                                <MirrorVerticalIcon></MirrorVerticalIcon>
                                            </button>
                                        </div>
                                        <div className="col-3">
                                            <button onClick={this.centerHorisontal}>
                                                <CenterHorisontalIcon></CenterHorisontalIcon>
                                            </button>
                                        </div>
                                        <div className="col-3">
                                            <button onClick={this.centerVertical}>
                                                <CenterVerticalIcon></CenterVerticalIcon>
                                            </button>
                                        </div>
                                    </div>
                            </div>
                            <div className="sliders">
                                <div className="row">
                                    <div className="col-6">
                                            <div className="slider_box">
                                                <div className="row">
                                                    <div className="col-9">
                                                        <label>{translator.t('change_ration')}</label>
                                                        <Slider
                                                            min={0}
                                                            max={2}
                                                            step={0.01}
                                                            value={this.state.scale}
                                                            onChange={this.scaleChange}
                                                        />
                                                    </div>
                                                    <div className="col-3 text-end">
                                                        <button className="revert-button" onClick={this.revertScale}>
                                                        <RevertIcon></RevertIcon>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                    <div className="col-6">
                                            <div className="slider_box">
                                                <div className="row">
                                                    <div className="col-9">
                                                        <label>{translator.t('change_angle')}</label>
                                                        <Slider
                                                            min={0}
                                                            max={360}
                                                            step={0.01}
                                                            value={this.state.angle}
                                                            onChange={this.angleChange}
                                                        />
                                                    </div>
                                                    <div className="col-3 text-end">
                                                        <button className="revert-button" onClick={this.revertAngle}>
                                                           <RevertIcon></RevertIcon>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                       </div>
                       }
                       <div className="arrow-container">
                           <button className="arrow-button" onClick={this.onOpenClick}>
                               <ArrowIcon  style={{ 'transform': this.state.open ? 'rotate(0)' : 'rotate(-180deg)'}}></ArrowIcon>
                           </button>
                       </div>
                   </div>
                }
                {
                    this.props.product &&
                    <div className="template_color">
                        <h2 className="template_color__title">{translator.t('add_background_color')}</h2>
                        <p className="template_color__tip">{translator.t('template_tip')}</p>
                        <SketchPicker color={(this.props.template) && (this.props.template.color) ? this.props.template.color:  '#fff' } onChange={this.onChangeColor}></SketchPicker>
                    </div>
                }
            </div>
        )
    }
}
