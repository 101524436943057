import React from 'react';
import Translator from '../../translator/translator';
import { ReactComponent as PenIcon } from '../../assets/pen.svg';

const translator = new Translator();

export default class AddText extends React.Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
            <div className="add-text-box">
                <div>
                    <button onClick={this.props.addTextClick} className="object-buttons">
                        <PenIcon></PenIcon>
                        <span>
                        { translator.t('add_text_text')}
                        </span>
                    </button>
                </div>
            </div>
        )
    }
}