import React,{ useEffect } from 'react';
import { Formik , Field } from "formik";
import axios from "axios";
import config from '../config';
import Translator from '../translator/translator';
import { Footer } from '../footer/footer';
import * as Yup from "yup";
import { ToastContainer, toast } from 'react-toastify';

const translator = new Translator();

export const Reset = ({ match })=>{

    const fetch = ()=>{
        axios.post(`${config.api}managers/check-reset`, { id: match.params.id })
        .then(res => {
            toast.success(translator.t('password_reset_success'), {
                position: toast.POSITION.TOP_RIGHT
            });
        }).catch((err)=>{
            toast.error(translator.t('password_reset_fail'), {
                position: toast.POSITION.TOP_RIGHT
            });
        });
    };

    useEffect(()=>{

        fetch();

    }, [])

    return(
        <main>
          <section className="s-block_authorization s-block_bottom authorization">
                <ToastContainer></ToastContainer>
          </section>
    </main>
    )
}

export default Reset;