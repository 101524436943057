const cutText = (text, length)=>{
    if(text){
        if(text.length <= length){
            return text;
        }else{
            return text.slice(0, length) + "...";
        }
    }
}

export default cutText;