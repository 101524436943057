
import React from 'react';
import { fabric } from 'fabric';
import ObjectID from "bson-objectid";
import ImageAdd  from './imageControls/imageAdd';
import Options from './options/options';
import Preview from '../preview/preview';
import Order from './order/order';
import Translator from '../translator/translator';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ReactComponent as RedoIcon } from '../assets/redo.svg';
import { ReactComponent as UndoIcon } from '../assets/undo.svg';
import { ReactComponent as ThreeDIcon } from '../assets/3d.svg';
import { ReactComponent as InfoIcon } from '../assets/info.svg';
import { ReactComponent as PenIcon } from '../assets/pen.svg';
import { ReactComponent as BagIcon } from '../assets/bag.svg';
import { ReactComponent as CloseIcon } from '../assets/close.svg';
import Requirements from '../assets/requirements.pdf';
import { ToastContainer, toast } from 'react-toastify';
import Switch from "react-switch";
import Popup from 'reactjs-popup';
import Checkbox  from "rc-checkbox";
import config from '../config';
import 'fabric-history';
import 'reactjs-popup/dist/index.css';
import 'rc-checkbox/assets/index.css';
import './editor.scss';
import { DepthTexture } from 'three';

const percentage =(num, per)=>{
  return (num/100)*per;
}

const translator = new Translator();

const axios = require('axios');

function extend(object, attr) {
  object.toObject = (function(toObject) {
    return function() {
      return fabric.util.object.extend(toObject.call(this), attr);
    };
  })(object.toObject);
}

let showGrid = false;
let orderData = {};
let prices = {};

class EditorTempalte extends React.Component{

  constructor(props){
    super(props);
    this.state = { selected: null, 
      product: null,
      objects:[],
      showGrid: false,
      showPreview: false,
      showInfo: false,
      printGrid: false,
      price: 0,
      showOrder: false,
      products: [],
      clip: null,
      template: null,
      bonus: null,
      index: 0,
      hide_price: false,
      preview_images: null
    };
    this.setIndex = this.setIndex.bind(this);
    this.setProduct = this.setProduct.bind(this);
    this.onPreviewClick = this.onPreviewClick.bind(this);
    this.canvasModifiedCallback = this.canvasModifiedCallback.bind(this);
    this.addTextClick = this.addTextClick.bind(this);
    this.onBackClick = this.onBackClick.bind(this);
    this.onForvardClick = this.onForvardClick.bind(this);
    this.onPdfClick = this.onPdfClick.bind(this);
    this.onShowGrid = this.onShowGrid.bind(this);
    this.onShowPreview = this.onShowPreview.bind(this);
    this.onShowInfo = this.onShowInfo.bind(this);
    this.onPrintGrid = this.onPrintGrid.bind(this);
    this.calcPrice = this.calcPrice.bind(this);
    this.onShowOrder = this.onShowOrder.bind(this);
    this.onOrderClick = this.onOrderClick.bind(this);
    this.onCloseOrder = this.onCloseOrder.bind(this);
    this.preview = React.createRef();
    this.setTemplate = this.setTemplate.bind(this);
    this.onSetTemplate = this.onSetTemplate.bind(this);
    this.createSquares = this.createSquares.bind(this);
    this.caclBonus = this.caclBonus.bind(this);
    this.getPdfDataCore = this.getPdfDataCore.bind(this);
    this.setSurfaceImage = this.setSurfaceImage.bind(this);
    this.clearGrid = this.clearGrid.bind(this);
    this.updateProduct = this.updateProduct.bind(this);
    this.deleteProduct = this.deleteProduct.bind(this);
    this.getPrice = this.getPrice.bind(this);
    this.setTemplateColor = this.setTemplateColor.bind(this);
    this.setColor = this.setColor.bind(this);
  }
  setColor(color){
    let product = this.state.product;
    product.color = color;
    this.setState({ product: product });
    this.canvasModifiedCallback();
  }
  setTemplateColor(color){
      let template = this.state.template;
      template.color = color;
      this.setState({ template: template });
      this.canvasModifiedCallback();
  }
  getPrice(){

    if(this.state.product) {

      let price = this.state.product.price;
      let count = this.state.product.count;
      let makeready_price = this.state.product.makeready_price;
      let printPrice = this.state.price;

      Object.keys(prices).forEach((key)=>{
        
        if(this.state.template._id !== key){
          printPrice += prices[key];
        }
      });

      let total = this.caclBonus(((price * count + makeready_price) + (printPrice * count)));

      return total.toFixed(2);

    }
    else{
      return 0;
    }
  }
  setProduct(product){

    let preview_images = {};

    product.templates.forEach((t)=>{
      preview_images[t._id] = null;
    });


    prices = Object.create(null);


    if(product.templates.length > 1){
      product.templates.forEach((t, i)=>{
          prices[t._id] = 0;
      });
    }

    orderData = {};

    let template = product.templates[0];

    let clip = template['clip'];

    this.setState({ product: product, clip: clip, template : template, preview_images: preview_images});

    this.setTemplate(template);

    this.canvasModifiedCallback();

    this.grid = null;

  }
  deleteProduct(){
    this.setState({ product: null, clip: null, template : null, objects: [], preview_images: null});
    this.setTemplate(null);
    this.grid = null;
    prices = {};
    orderData = {};
  }
  updateProduct(data){
    let product = this.state.product;
    product['count'] = data.count;
    product['size'] = data.size;
    product['price'] = data.price;
    product['color'] = data.color;
    this.setState({ product: product });
    this.canvasModifiedCallback();
  }
  setIndex(index){
    this.setState({ index: index });
  }
  caclBonus(price){
     if(this.state.bonus){
         let bonus = percentage(price,this.state.bonus);
         let final =  price + bonus;
         return final;
     }else{
       return price;
     }
  }

  clearGrid(){
    this.setState({ showGrid : false });
    if(this.grid){
      this.grid.remove();
      this.grid = null;
    }
    let object = this.canvas.getObjects();
    let final = object.filter((o)=>{ return !o.isGrid});
    this.canvas._objects = final;
  }

  onBackClick(){
    this.canvas.undo();
    this.clearGrid();
    this.canvasModifiedCallback();
  }

  onForvardClick(){
    this.canvas.redo();
    this.clearGrid();
    this.canvasModifiedCallback();

  }

  onOrderClick(order){
    
     const _this = this;

     let manager_id = !this.props.own ? this.props.match.params.manager : null;
     let product_id = this.state.product._id;
     let size = this.state.product.size;
     let clearBase  = this.state.product.price * this.state.product.count;
     let printPrice = this.state.price;

     Object.keys(prices).forEach((key)=>{

       if(this.state.template._id !== key){
         printPrice += prices[key];
       }

     });

     const doOrder = (preview)=>{

        let clearTypogrphy  =  printPrice * this.state.product.count;
        let priceBase = this.caclBonus(clearBase);
        let priceTypoGraphy = this.caclBonus(clearTypogrphy);
        let totalPrice = priceBase + priceTypoGraphy;
        let bonus = totalPrice - (clearBase + clearTypogrphy);
        let product = this.state.product;
    
        let text = {
            title :product.title,
            priceBase: priceBase,
            priceTypoGraphy: priceTypoGraphy,
            color: product.colors.length ? product.colors[0]['hex']: "",
            count: this.state.product.count
        }

        this.getPdfData().then((result)=>{

            let pdfData =  { 
              objects : result
            }

            let data = {
              quantity: this.state.product.count,
              total: priceBase + priceTypoGraphy + this.state.product.makeready_price,
              typography_total: priceTypoGraphy,
              manager_id: manager_id,
              product_id: product_id,
              firstname: order.firstname,
              lastname: order.lastname,
              phone: order.phone,
              email: order.email,
              comment: order.comment,
              address: order.address,
              company: order.company,
              link: order.link,
              preview: preview,
              pdf: pdfData,
              size: size,
              own: _this.props.own,
              text,
              bonus
            };
      
            axios.post(`${config.api}orders/create`, data)
            .then(res => {
              _this.setState({ showOrder: false });
                toast.success(translator.t('order_success'), {
                  position: toast.POSITION.TOP_CENTER
                });
            }).catch((err => {
              _this.setState({ showOrder: false });
            }));

        });

     }
     
     const loadImage = (base64, name)=>{
       return new Promise((resolve, reject)=>{
          var image = new Image();
          image.onload = function() {
            resolve({image , name});
          };
          image.src = base64;
       });
     }

     let previewTaks = [];

     Object.keys(this.state.preview_images).forEach((key)=>{

      let name = "";

      this.state.product.templates.forEach((template)=>{
           if(template._id === key){
              name = template["name_" + translator.locale];
           }
      });

      let base64 = _this.state.preview_images[key];
      if(base64){
        previewTaks.push(loadImage(base64, name));
      }

     });

     if(previewTaks.length){

        Promise.all(previewTaks).then((images)=>{
        
          let canvasP = document.createElement('canvas');
          let ctx =  canvasP.getContext("2d");
          let width = 0;
          let height = 0;
          let y = 0;

          images.forEach((item)=>{
            let img = item.image;
            if(img.width > width){
              width = img.width;
            }
            height += img.height;
          });

          canvasP.width = width;
          canvasP.height = height;

          images.forEach((item)=>{
            let img = item.image;
            ctx.font = "30px Arial";
            ctx.fillText(item.name,(width/2) - 30, y+30);
            y+= 40;
            ctx.drawImage(img, 0, y, img.width, img.height);
            y += img.height;
          });

           doOrder(canvasP.toDataURL());
        });

     }else{
        doOrder(_this.canvas.toDataURL());
     }


  }

  onCloseOrder(){
    this.setState({ showOrder: false });
  }

  onShowOrder(){
     this.setState({ showOrder: true });
  }

  draw_grid(show, callback) {

    if(!this.grid){
      
      let gritMM = 10;
      let w = this.state.template.w;

      let squaresCount = w / gritMM;
      
      let clip = this.state.clip;
      
      let _this = this;
      let width = clip.width;
      let height = clip.height;
      let canvasWidth = width;
      let canvasHeight = height;
      let grid = width / squaresCount;

      var group = new fabric.Group();
      let length = (canvasWidth > canvasHeight) ? canvasWidth : canvasHeight;
      for (var i = 0; i < (length / grid); i++) {
       group.addWithUpdate(new fabric.Line([ i * grid, 0, i * grid, canvasHeight], { type:'line', stroke: '#ccc', selectable: false, strokeWidth: 3 }));
         group.addWithUpdate(new fabric.Line([ 0, i * grid, canvasWidth, i * grid], { type: 'line', stroke: '#ccc', selectable: false, strokeWidth: 3}));
      }
      group.addWithUpdate(new fabric.Line([ canvasWidth-1, 0, canvasWidth-1, canvasHeight], { type:'line', stroke: '#ccc', selectable: false, strokeWidth: 3 }));
      group.addWithUpdate(new fabric.Line([ 0, canvasHeight-1, canvasWidth-1, canvasHeight-1], { type:'line', stroke: '#ccc', selectable: false, strokeWidth: 3 }));
  
      group.set({
        top:clip.top,
        left:clip.left,
        selectable: false,
        clipPath: clip,
        evented: false
      });
  
      group.isGrid = true;
    
      group.hasControls = false;
      group.hasBorders = false;
      _this.canvas.add(group);
      _this.grid = group;
  
      _this.canvas.sendToBack(group);
    }else if(show){
      this.grid.opacity = 1;
    }else{
      this.grid.opacity = 0;
    }

    this.canvas.renderAll();

    if(callback){
      callback();
    }

 }

  onPrintGrid(){
    this.setState({ printGrid : !this.state.printGrid });
  }

  onShowInfo(){
    this.setState({ showInfo : !this.state.showInfo });
  }

  onShowGrid(){
    showGrid = !this.state.showGrid;
    this.setState({ showGrid : !this.state.showGrid });
    this.draw_grid(showGrid);
  }

  setSurfaceImage(){

    let _this = this;

    let dataURL = _this.preview_canvas.toDataURL({
      format: "image/png",
      multiplier: 2,
      left: _this.state.clip.left,
      top: _this.state.clip.top,
      width: _this.state.clip.width ,
      height: _this.state.clip.height ,
    });

    let preview_images = this.state.preview_images;

    Object.keys(preview_images).forEach((key)=>{
      if(key === this.state.template._id){
        preview_images[key] = dataURL;
      }
    });

    this.setState({ preview_images: preview_images });

  }

  onShowPreview(){

    this.setSurfaceImage();
    this.setState({ showPreview: !this.state.showPreview });

  }

  getPdfDataCore(key){

     let  _this = this;
     _this.canvas.clear();

     let objects = orderData[key];

     objects.forEach((obj)=>{
      _this.canvas.add(obj)
     });

     _this.canvas.renderAll();
     
     let template = this.state.product.templates.filter((t)=>{ return t._id === key })[0];
     let clip = template['clip'];
     let realWidth = template['w'];
     let realHeight = template['h'];

     return new Promise((resolve, reject)=>{

      let data = [];

      let objects = this.canvas.getObjects();

      objects.forEach((object)=>{
        if(object.isText){
          let obj = {
            text: object.text,
            y: object.top,
            x: object.left,
            fontFamily: object.fontFamily,
            fontSize: object.fontSize,
            fontWeight: object.fontWeight,
            angle: object.angle,
            isText: true,
            color: object.fill,
            width: object.width,
            height: object.height
          }
          data.push(obj);
        }
        if(object.isImage){
          let obj = {
            y: object.top,
            x: object.left,
            angle: object.angle,
            isImage: true,
            width: object.width,
            height: object.height,
            scaleX: object.scaleX ?  object.scaleX : 1,
            scaleY: object.scaleY ?  object.scaleY : 1,
            image: object._element.currentSrc
          }

          data.push(obj);
        }
      });

      resolve({
        objects: data,
        clip: clip,
        img: template['img_original'],
        realWidth,
        realHeight 
      });

     });
  }

  getPdfData(){

    let  _this = this;
    
    return new Promise((resolve, reject)=>{

      _this.canvas.backgroundImage = false;
      _this.canvas.backgroundColor = null;

      orderData[_this.state.template._id] = _this.canvas.getObjects();

      let tasks = [];

      Object.keys(orderData).forEach((key)=>{
        tasks.push(_this.getPdfDataCore(key));
      });

      Promise.all(tasks).then((result)=>{

          _this.canvas.clear();

          let objects = orderData[_this.state.template._id];

          objects.forEach((obj)=>{
           _this.canvas.add(obj);
          });

          fabric.Image.fromURL(_this.state.template.img,(img)=>{
            _this.canvas.setBackgroundImage(img, _this.canvas.renderAll.bind(_this.canvas), {
              scaleX: _this.canvas.width / img.width,
              scaleY: _this.canvas.height / img.height
            });
          }, { crossOrigin: 'Anonymous' });

          resolve(result);
      });

    });

  }

  onPdfClick(){

    let product = this.state.product;
    let priceBase = this.caclBonus(this.state.product.price * this.state.product.count);
    let priceTypoGraphy = this.caclBonus(this.state.price * this.state.product.count);

    let text = {
       title :product.title,
       priceBase: priceBase,
       priceTypoGraphy: priceTypoGraphy,
       color: product.colors[0]['hex'],
       count: this.state.product.count
    }

    this.setSurfaceImage();

    let _this = this;

    setTimeout(()=>{

      _this.getPdfData().then((result)=>{

        _this.preview.current.getImages().then((images)=>{
  
           let pdfData =  { 
             objects : result
           }
  
           let data = {
              pdf: pdfData, 
              images: images,
              text: text
           }
    
          axios.post(`${config.api}pdf/create`, data)
          .then(function (response) {
              let link = document.createElement('a');
              link.target = '_blank';
              link.download = 'pdf';
              link.href = `${config.api}${response.data}`
              link.click();
          })
          .catch(function (error) {
            console.log(error);
          });
  
        });
      
      });
    },1000);

  }

  createPreview(){}

  onPreviewClick(){

    let _this = this;
    let product = this.state.product;

    this.setSurfaceImage();

    this.setState({ showPreview : true , screenshot: true });

    const drawImage = (ctx, x, y, width, height, src)=>{
      return new Promise((resolve, reject)=>{
        if(src){
          let image = new Image();
          image.crossOrigin="anonymous"
          image.onload = ()=>{
            let aspect = image.width / width;
            ctx.drawImage(image, 0, 0, image.width, image.height, x ,y , width, (image.height / aspect));
            resolve();
          };
          image.src = src; 
        }else{
          resolve();
        }
      });
    }

    function drawCircle(ctx, x, y, radius, fill, stroke, strokeWidth) {
      ctx.beginPath()
      ctx.arc(x, y, radius, 0, 2 * Math.PI, false)
      if (fill) {
        ctx.fillStyle = fill
        ctx.fill()
      }
      if (stroke) {
        ctx.lineWidth = strokeWidth
        ctx.strokeStyle = stroke
        ctx.stroke()
      }
    }

    this.setSurfaceImage();

    let canvas = document.getElementById('png');
    let ctx = canvas.getContext('2d');
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const wrapText = (context, text, x, y, maxWidth, lineHeight)=> {
      context.font = "13px Roboto";
      context.fillStyle = '#99A0A3';
      var words = text.split(' ');
      var line = '';

      for(var n = 0; n < words.length; n++) {
        var testLine = line + words[n] + ' ';
        var metrics = context.measureText(testLine);
        var testWidth = metrics.width;
        if (testWidth > maxWidth && n > 0) {
          context.fillText(line, x, y);
          line = words[n] + ' ';
          y += lineHeight;
        }
        else {
          line = testLine;
        }
      }
      context.fillText(line, x, y);
      return y;
    }

    const drawLine = (x,y)=>{
      ctx.beginPath();
      ctx.moveTo(x,y);
      ctx.lineTo(x+435,y);
      ctx.closePath();
      ctx.stroke();
    }

    const writeName = (x, y, text)=>{
      ctx.fillStyle = "#262628";
      ctx.font = "bold 16px Roboto";
      ctx.fillText(text, x, y);
    }

    const drawPngCore = ()=>{

      drawImage(ctx, 30, 30 , 200, 200, _this.state.logo).then(()=>{

        _this.preview.current.getImages().then((images)=>{

          _this.setState({ showPreview : false, screenshot: false });
  
          drawImage(ctx, 30, 200, 300, 333, images[0]).then(()=>{
            drawImage(ctx, 355, 200, 300, 500, images[1]).then(()=>{
              drawImage(ctx, 680, 200, 300, 500, images[2]).then(()=>{
  
                 ctx.save();
  
                 ctx.shadowColor = '#f0f0f0';
                 ctx.shadowBlur = 30;
                 ctx.fillStyle = '#fff';
                 ctx.fillRect(30, 570, 965, 400);
  
  
                 ctx.shadowColor= undefined; 
                 ctx.shadowBlur = undefined; 
                 
                 ctx.restore();
  
                 ctx.fillStyle = "#262628";
                 ctx.font = "bold 25px Roboto";
                 ctx.fillText(translator.t('order_info'), 370, 540);
  
                const maxWidth = 150;
                ctx.strokeStyle = "#f0f0f0";
                
                 writeName(65, 608, translator.t('product_name'));
                 let y  = wrapText(ctx, product['title_' + translator.locale], (canvas.width/2) - maxWidth, 608, maxWidth, 15);
                 y += 20;
                 drawLine(65, y);
                 y += 30;
  
                 writeName(65, y, translator.t('category'));
                 y  = wrapText(ctx, translator.t(product.category), (canvas.width/2) - maxWidth, y, maxWidth, 15);
                 y += 20;
                 drawLine(65, y);
                 y += 30;
  
                 writeName(65, y, translator.t('sub_category'));
                 y  = wrapText(ctx, translator.t(product.sub_category), (canvas.width/2) - maxWidth, y, maxWidth, 15);
                 y += 20;
                 drawLine(65, y);
                 y += 30;
  
                 writeName(65, y,  translator.t('type'));
                 y  = wrapText(ctx, translator.t(product.type), (canvas.width/2) - maxWidth, y, maxWidth, 15);
         
                if(product.color){
                  y = 608;
                  writeName(530, y, translator.t('color'));
                  y  = wrapText(ctx, product.color, (canvas.width) - maxWidth, y, maxWidth, 15);
                  drawCircle(ctx, ((canvas.width) - (30)) - 30, y-5, 10, product.color);
                 }
                 
                 y += 20;
                 drawLine(530, y);
                 y += 30;
  
                 if(product.size){
                   product.size.forEach((size, i)=>{
                      writeName(530, y, translator.t('quantity'));
                      wrapText(ctx, size.count + '', 650, y, maxWidth, 15);
                      writeName(865, y,  translator.t('size') );
                      wrapText(ctx, size.size.label + '', 950, y, maxWidth, 15);
                      y += 20;
                      if(i != (product.size.lrngth -1)){
                        drawLine(530, y);
                        y += 30;
                      }
                   });
                 }
  
                  if(_this.state.printGrid){
                     ctx.font = "16px Roboto";
                     ctx.fillStyle = '#a3a3a3';
                    //  let price = _this.caclBonus((_this.state.product.price * _this.state.product.count) + (_this.state.price * _this.state.product.count)).toFixed(2) + translator.t('total_price_print_grn');
                     let price = _this.getPrice() + translator.t('total_price_print_grn');
                     let textWidth = ctx.measureText(price).width;
                     ctx.fillText(price, (1020-30) - textWidth, 1000);
                     ctx.fillStyle = '#FFA400';
                     let label = translator.t('total_price_print');
                     let labelWidth =  ctx.measureText(label).width;
                     ctx.fillText(label, (1020-30) - (textWidth+labelWidth), 1000);
                  }
  
                  let dataURL = canvas.toDataURL();
          
                  let a = document.createElement("a"); 
                  a.href = dataURL;
                  let date = new Date();
                  let dateString = (date.getDate() < 9 ? '0' + date.getDate() : date.getDate()) + '.' + (date.getMonth() < 9 ? '0' + (date.getMonth()+1) : (date.getMonth() + 1) ) + '.' + date.getFullYear() + "-" + (date.getHours() < 9 ? '0' + date.getHours() : date.getHours() ) + "-" + (date.getMinutes() < 9 ? '0' + date.getMinutes() : date.getMinutes() );
                  a.download = "preview-" + dateString + ".png"; 
                  a.click(); 

              });
            });
          });
  
        });

  
      });
    }
    
    drawPngCore();
  }

  addTextClick(){

    let id = ObjectID().toHexString();

    let text = new fabric.Text('Текст', { 
        fontFamily: 'Arial',
        textAlign: 'center',
        fontWeight: 'normal',
        fontSize: 120,
        clipPath: this.state.clip,
        id: id,
        isText: true
    });

    extend(text, {
      id : id,
      isText : true
    });


    let template = this.state.clip;

    let top = (template.top + (template.height / 2)) - (text.height / 2);
    text.setCoords();
    text.set("top",top);

    let left = (template.left + (template.width / 2)) - (text.width / 2);
    text.set("left",left)

    text.userFont = { value: 'Arial', label: 'Arial' };
    text.userFontWeight = { value: 'normal', label: 'normal' };

    this.canvas.add(text);
  
    this.canvas.setActiveObject(text);
  }

  setTemplate(template){

    this.canvas.clear();

    if(this.grid){
      this.grid.remove();
      this.grid = null;
    }

    //this.setState({ showGrid : false });

    if(template){

      let _this = this;

      fabric.Image.fromURL(template.img,(img)=>{
          _this.canvas.setBackgroundImage(img, _this.canvas.renderAll.bind(_this.canvas), {
             scaleX: _this.canvas.width / img.width,
             scaleY: _this.canvas.height / img.height
          });
      }, { crossOrigin: 'Anonymous' });
  
      this.createSquares(template);

      if(orderData[template._id]){
        let objects = orderData[template._id];
        objects.forEach((obj)=>{
           _this.canvas.add(obj)
        });
      }
      

    }
  }

  onSetTemplate(e){
    this.setSurfaceImage();

    if(this.state.template){
      let currentObjects = this.canvas._objects.filter((o)=>{ return !o.isGrid });
      orderData[this.state.template._id] = currentObjects;
      if(this.state.template.full_print){
        if(currentObjects.length){
           prices[this.state.template._id] = this.state.template.print_price;
        }else{
           prices[this.state.template._id] = 0;
        }
      }else{
           prices[this.state.template._id] = this.state.price;
      }
    }
    
    let template = this.state.product.templates.filter((t)=>{  return t._id === e.target.value })[0];
    let clip = template['clip'];
    this.setTemplate(template);
    this.setState({ clip: clip, template: template, showGrid : false });
  
  }

  createSquares(template){

    let clip = template['clip'];

    let gritMM = 10;
    let w = template.w;
    let h = template.h;

    let squaresMM = w / gritMM;

    let clipWidth = clip.width;
    let clipHeight = clip.height;

    let grid = clipWidth / squaresMM;
    let squares = [];
    let stepX = parseInt( clipWidth / grid);
    let stepY = parseInt( clipHeight / grid);
    let y = 0;

    for( var s = 0; s < stepY; s++){
      for(var i = 0; i < stepX; i++){
        let x = i * grid;
        let square = {
          'point1': { 
            x: x,
            y: y
          },
          'point2': { 
            x: x+grid,
            y: y
          },
          'point3': { 
            x: x,
            y: y+grid
          },
          'point4': { 
            x: x+grid,
            y: y+grid
          }
        }
        squares.push(square);
      }
      y += grid;
    }
    this.setState({ squares: squares} );

  }

  calcPrice(base64, width, height){

    const _this = this;

    let square_price = this.state.template.print_price / this.state.squares.length; 

    setTimeout(()=>{

        let canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;

        let image = new Image();
        image.onload = function() {

           canvas.getContext('2d').drawImage(image,0,0);

           let filledSquares = 0;

           _this.state.squares.forEach((square,i)=>{

               let colors = canvas.getContext('2d').getImageData(square.point1.x, square.point1.y, square.point2.x - square.point1.x, square.point2.x - square.point1.x).data;
               let filled = false;

               for(let i = 0; i < colors.length; i++){

                 if((!filled) && (colors[i] !== 255)){

                   filled = true;
                   filledSquares++;

                 } 
               }

           }); 
           if(_this.state.template.full_print && filledSquares){

            _this.setState({ price:  _this.state.template.print_price });

           }else{

              _this.setState({ price: filledSquares * square_price});

           }
        };
        image.src = base64;
    },1);

  }

  canvasModifiedCallback(e){

      if(e && e.target.isGrid){
        return;
      }

      let _this = this;

      setTimeout(()=>{

        if((_this.state.product) || (_this.state.clip) || (_this.state.template)){
          let clip = _this.state.clip;

          if(_this.grid){
            _this.grid.opacity = 0;
          }

          setTimeout(()=>{

            _this.calc_canvas._objects = _this.canvas._objects.filter((o)=>{ return !o.isGrid });
            _this.calc_canvas.backgroundColor = "#fff";
            _this.calc_canvas.renderAll();
    
            _this.preview_canvas._objects = _this.canvas._objects.filter((o)=>{ return !o.isGrid });
  
            if(_this.state.template && _this.state.template.color){
              _this.preview_canvas.backgroundColor = _this.state.template.color;
            }
            else if(_this.state.template && _this.state.template.fill && _this.state.product.color){
              _this.preview_canvas.backgroundColor = _this.state.product.color;
            }
            
            _this.preview_canvas.renderAll();
    
            var dataURL = _this.calc_canvas.toDataURL({
              format: "jpeg",
              left: clip.left,
              top: clip.top - 1,
              width: clip.width + 1 ,
              height: clip.height
            });
    
            _this.calcPrice(dataURL,clip.width, clip.height);

          },1);
  
          if(_this.grid){
            if(showGrid){
              _this.grid.opacity = 1;
            }
          }
  
          let objects = this.canvas.getObjects();
          objects.forEach((obj)=>{
            if(obj.text){
              obj.isText = true;
            }
            if(obj.cacheKey){
              obj.isImage = true;
            }
          });
  
          fabric.Image.fromURL(_this.state.template.img,(img)=>{
            _this.canvas.setBackgroundImage(img, _this.canvas.renderAll.bind(_this.canvas), {
               scaleX: _this.canvas.width / img.width,
               scaleY: _this.canvas.height / img.height
            });
          }, { crossOrigin: 'Anonymous' });
  
          _this.canvas.renderAll();
          _this.setState({ objects: objects });
          _this.forceUpdate();
        }

      },1)
  }

  componentDidMount(){

    let _this = this;
    let width = 1800;
    let height = 1800;

    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerColor = 'blue';
    fabric.Object.prototype.cornerSize = 30;
    fabric.Object.prototype.cornerStyle = 'circle';

    let canvas = new fabric.Canvas('canvas', {
      height: height,
      width: width,
      preserveObjectStacking: true,
    });

    this.canvas = canvas;

    let calc_canvas = new fabric.Canvas('calc_canvas', {
      height: height,
      width: width,
      preserveObjectStacking: true,
    });

    let preview_canvas = new fabric.Canvas('preview_canvas', {
      height: height,
      width: width,
      preserveObjectStacking: true,
    });

    this.calc_canvas = calc_canvas;
    this.preview_canvas = preview_canvas;

    canvas.on('object:added', this.canvasModifiedCallback);
    canvas.on('object:removed', this.canvasModifiedCallback);
    canvas.on('object:modified', this.canvasModifiedCallback);
    canvas.on('object:scaling', this.canvasModifiedCallback);
    canvas.on('object:rotating', this.canvasModifiedCallback);
    canvas.on('object:skewing', this.canvasModifiedCallback);
    canvas.on('object:moved', this.canvasModifiedCallback);
    canvas.on('object:skewed', this.canvasModifiedCallback);

    const checkControls = (e)=>{
      if(e.target.isText){
        e.target.setControlsVisibility({
            mt: false,
            mb: false, 
            ml: false,
            mr: false, 
            bl: false,
            br: false,
            tl: false,
            tr: false,
        });
      }if(e.target.isImage){
        e.target.setControlsVisibility({
            mt: true,
            mb: true, 
            ml: true,
            mr: true, 
            bl: true,
            br: true,
            tl: true,
            tr: true,
        });
      }
    }


    canvas.on('selection:created', (e)=> {

      checkControls(e);

      if(e && e.target){ 
        let index = 0;
        if(e.target.text){
          index = 2;
        }else if(e.target.cacheKey){
          index = 1;
        }
        if(window.innerWidth > 850){
          _this.setState({ selected: e.target, index: index});
        }else{
          _this.setState({ selected: e.target});
        }
      }
    });
    
    canvas.on('selection:cleared', (e)=> {
      _this.setState({ selected: null });
    });
    
    canvas.on('selection:updated', (e)=> {
      checkControls(e);
      _this.setState({ selected: e.target });
      if(e && e.target){ 
        let index = 0;
        if(e.target.text){
          index = 2;
        }else if(e.target.cacheKey){
          index = 1;
        }
        if(window.innerWidth > 850){
          _this.setState({ selected: e.target, index: index});
        }else{
          _this.setState({ selected: e.target});
        }
      }
    });

    this.forceUpdate();

    if(this.props.own){
      _this.setState({ bonus: 0, logo:  null, hide_price: false });
    }else if(this.props.match.params.manager){
      var currentUrl = document.referrer;
      if(currentUrl){
        translator.setLocale(this.props.match.params.lang);
        const { hostname } = new URL(currentUrl);
        axios.get(`${config.api}managers/get-settings?id=${this.props.match.params.manager}&hostname=${hostname}`)
        .then(res => {
            let responseData = res.data;
            _this.setState({ bonus: responseData.bonus, logo: responseData.logo ? config.api + responseData.logo: null, hide_price: responseData.hide_price });
        });
      }else{
        const { hostname } = new URL(window.location.href);
        translator.setLocale(this.props.match.params.lang);
        axios.get(`${config.api}managers/get-settings?id=${this.props.match.params.manager}&hostname=${hostname}`)
        .then(res => {
            let responseData = res.data;
            _this.setState({ bonus: responseData.bonus, logo: responseData.logo ? config.api + responseData.logo: null, hide_price: responseData.hide_price });
        });
      }
    }else{
      _this.setState({ bonus: null});
    }

  }

  render(){

    let templates = this.state.product ? this.state.product.templates.map((t)=>{
      return(
        <div className="template" key={t._id} style={{ boxShadow: this.state.template._id === t._id ? 'rgb(17 17 17 / 20%) 4px 8px 12px' : 'none'}}>
          <button value={t._id} onClick={this.onSetTemplate}></button>
          <img src={`${t.img}`} alt="img"></img>
          <p>{t['name_' + translator.locale]}</p>
        </div>
      )
    }): [];
    
    return(
      <div className="editor-container" id="3d">
        {
          true &&
          <div className="container-fluid" style={{ display:  (this.state.bonus == null || this.state.bonus == undefined) ? 'none' : 'block '}}>
              <div className="row editor-box">
                  <div className="editor-column">
                      {
                        !this.state.product &&
                        <div className="editor-overlay">
                          <h1  className="editor-overlay-text">{translator.t('choose_product')}</h1>
                        </div>
                      }
                      <div className="templates">
                        <PerfectScrollbar>
                          <div className="templates-inner">
                            <h3 className="templates-title">{translator.t('print_zones')}</h3>
                            {
                              templates
                            }
                          </div>
                        </PerfectScrollbar>
                      </div>
                      <button className="show-preview" onClick={this.onShowPreview}>
                          <ThreeDIcon></ThreeDIcon>
                      </button>
                      <div className="history">
                          <button onClick={this.onBackClick} className="history-buttons">
                            <UndoIcon/>
                          </button>
                          <button onClick={this.onForvardClick} className="history-buttons">
                            <RedoIcon/>
                          </button>
                      </div>
                      <div className="editor-wrapper">
                        <canvas id="canvas" />
                        <div className="calc_canvas">
                           <canvas id="calc_canvas" />
                        </div>
                        <div className="calc_canvas">
                           <canvas id="preview_canvas" />
                        </div>
                      </div>
                      <div className="editor-bottom-box">
                        <div className="editor-function">
                          <div className="row">
                            <div className="col-4">
                              <button className="info-button" onClick={this.onShowInfo}>
                                <InfoIcon></InfoIcon>
                                <span className="info-button-text">{ translator.t('image_reqirments')}</span>
                              </button>
                            </div>
                            <div className="col-4">

                            </div>
                            <div className="col-4 editor-network-box">
                                <div className="editor-network grid-switcher grid-switcher-right">
                                      <Switch 
                                          onChange={this.onShowGrid} 
                                          checked={this.state.showGrid} 
                                          uncheckedIcon={
                                            <div className="grid-text grid-off">{ translator.t('show_grid_on')}</div>
                                          }
                                          checkedIcon={
                                            <div className="grid-text grid-off">{ translator.t('show_grid_off')}</div>
                                          }
                                          height={35}
                                          width={70}
                                          handleDiameter={28}
                                          boxShadow={'none'}
                                          activeBoxShadow={'none'}
                                          offColor={'#FFA400'}
                                          onColor={'#262628'}
                                          offHandleColor={'#262628'}
                                          onHandleColor={'#FFA400'}
                                    />
                                      <label>
                                        <span>{ translator.t('show_grid_top')}</span>
                                        <span>{ translator.t('show_grid_bottom')}</span>
                                      </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="container-fluid editor-bottom">
                        <div className="row">
                          <div className="row editor-btns">
                            <ImageAdd canvas={this.canvas} product={this.state.product} clip={this.state.clip}></ImageAdd>
                            <div>
                                <button onClick={this.addTextClick} className="object-buttons">
                                    <PenIcon></PenIcon>
                                    <span>
                                      { translator.t('add_text_text')}
                                    </span>
                                </button>
                            </div>
                          </div>
                          <div className="col-4 editor-download">
                              <button onClick={this.onPreviewClick} className="download">{ translator.t('download_png')}</button>
                              {
                                  !this.state.hide_price &&
                                  <div className="check-box-row">
                                    <Checkbox onChange={this.onPrintGrid} checked={this.state.printGrid}> </Checkbox>
                                    <label className="check-box-row-text">{ translator.t('print_price')} </label>
                                  </div>
                                }
                          </div>
                        </div>
                      </div>
                      </div>
                  </div>
                  <div className={ this.state.product === null ? 'options-column' : 'options-column options-column_hide'}>
                      {
                        this.canvas &&
                        <Options setTemplateColor={this.setTemplateColor} setColor={this.setColor} template={this.state.template} addTextClick={this.addTextClick} deleteProduct={this.deleteProduct} translator={translator} hide_price={this.state.hide_price} updateProduct={this.updateProduct} setIndex={this.setIndex} index={this.state.index} objects={this.state.objects} canvas={this.canvas} selected={this.state.selected} products={this.state.products} clip={this.state.clip}   product={this.state.product} setProduct={this.setProduct} canvasModifiedCallback={this.canvasModifiedCallback}></Options>
                      }
                      <div className="price_wrapper">
                        <div className="row">
                          <div className="col-5 col-sm-6 price-box">
                              {
                                 !this.state.hide_price &&
                                 <p>{ translator.t('price')}: <span>{ this.getPrice() } { translator.t('grn')}</span></p>
                              }
                          </div>
                          <div className="col-7 col-sm-6 order-btn-box">
                            <button className="b-btn" onClick={this.onShowOrder} disabled={!this.state.product}>
                              { translator.t('order')}
                              <BagIcon></BagIcon>
                            </button>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
                {
                  this.state.product && this.state.showPreview && 
                  <div className="preview_wrapper" style={{ 'display': this.state.screenshot ? 'none' : 'block'}}>
                      <div className="preview_trigger" onClick={this.onShowPreview}></div>
                      <Preview  ref={this.preview} product={this.state.product} clip={this.state.clip} template={this.state.template} onCloseClick={this.onShowPreview} preview_images={this.state.preview_images}></Preview>
                  </div>
                }
                <Popup open={this.state.showInfo} modal  position="top left" onClose={this.onShowInfo}>
                    {close => (
                      <div className="b-popup b-popup_img">
                        <button className="close" onClick={close}>
                          <CloseIcon></CloseIcon>
                        </button>
                        <h2 className="b-popup__title">{ translator.t('image_reqirments_title')}</h2>
                        <p className="b-popup__text">
                           { translator.t('image_reqirments_description')}
                        </p>
                        <div className="b-popup__text">
                          <a className="b-popup__text b-popup__link" href={Requirements} target="_blank">
                            { translator.t('image_reqirments_link')}
                          </a>
                        </div>
                      </div>
                    )}
                </Popup>
                <Popup open={this.state.showOrder} modal  position="top left" onClose={this.onCloseOrder}>
                    <div className="inner-popup">
                        <button className="close" onClick={this.onCloseOrder}>
                          <CloseIcon></CloseIcon>
                        </button>
                        <Order translator={translator} onOrderClick={this.onOrderClick}></Order>
                    </div>
                </Popup>
                <canvas id="png" width="1020" height="1020"></canvas>
          </div>
        }
        <ToastContainer></ToastContainer>
      </div>
    )
  }
}

export default EditorTempalte;


