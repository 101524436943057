export default {
  "news": "Новини",
  "education": "Навчання",
  "profile": "Особистий кабінет",
  "contacts": "Контакти",
  "autorization": "Авторизація",
  "login": "Увійти",
  "email": "Пошта",
  "password": "Пароль (мін. 8 символів)",
  "confirm_password": "Підтвердження паролю",
  "type_email": "Написати пошту",
  "type_password": "Написати пароль",
  "type_name": "Написати ім'я",
  "type_phone": "Написати телефон",
  "type_comment": "Написати коментарі",
  "all_news": "Всі новини",
  "read_more": "Читати статтю",
  "address": "Адрес",
  "phone": "Телефон",
  "write_to_typography": "Написати в типографію",
  "client_name": "Ім'я",
  "client_phone": "Номер телефону власника облікового запису",
  "copyrights": "Sborka-3D",
  "developers": "Розробка та підтримка:",
  "send": "Відправити",
  "typogrphy": "Типографія Ультрадрук",
  "adds": "Світ реклами",
  "souvenir": "Сувеніри та поліграфія",
  "forgot": "Забули пароль?",
  "not_robor": "Я не робот",
  "ideas": "Ідеї друку",
  "employees": "Співробітникам",
  "selected_clients": "Обраним клієнтам",
  "mass_distrubution": "Масове поширення",
  "to_leaders": "Керівникам",
  "for_partners": "Партнерам",
  "for_family": "Сім'ї",
  "for_friends": "Друзям",
  "register": "Реєстрація",
  "name": "Ім'я",
  "company": "Назва компанії",
  "all": "Все",
  "back_news": "Повернутись до новин",
  "back_education": "Повернутись до навчання",
  "recall": "Замовити зворотній дзвінок",
  "write_support": "Напишіть у нашу службу підтримки",
  "comment": "Коментар",
  "delivery": "Інформація про доставку",
  "step": "Крок",
  "required": "Обов'язково",
  "must_check": "Поле має бути відмічено",
  "sent": "Надіслано",
  "must_match": "Паролі повинні збігатися",
  "sent_error": "Помилка під час відправлення",
  "autorization_error": "Помилка авторизації",
  "last_news": "Останні новини",
  "logout": "Вихід",
  "register_success": "Очікуйте дзвінок від адміністратора",
  "user_exist": "Користувач із таким email вже існує",
  "user_not_exists": "Користувача із таким email не існує",
  "reset_link_sent": "Лист із посиланням надіслано на пошту",
  "password_reset_success": "Новий пароль надіслано Вам на пошту",
  "password_reset_fail": "збій зміни пароля",
  "lorem": "Чи знали ви, що перемога на виборах на 80% залежить від успіху передвиборчої агітації? Чим виграшніший дизайн поліграфії та точніше підібрані тригери, тим вищий шанс завоювати увагу виборця! При цьому кожен кандидат у депутати має знати: виготовляти агітаційні матеріали дозволено лише у повній відповідності до чинного законодавства України.",
  "image_reqirments" : "Вимоги до зображень",
  "image_reqirments_title" : "Вимоги до зображення",
  "image_reqirments_description" : "Можна завантажувати зображення в PNG. Максимальний розмір: 5 МБ. Завантаження великих зображень може тривати певний час.",
  "image_reqirments_link" : "Скачати вимоги до зображення",
  "show_grid_top" : "Відобразити сітку",
  "show_grid_bottom" : "1 квадрат = 10х10мм",
  "show_grid_on" : "Увімк",
  "show_grid_off" : "Вимк",
  "add_image_text" : "Додати зображення",
  "choose_file": "Вибрати файл",
  "choose_product": "Оберіть товар",
  "add_text_text" : "Додати текст",
  "add_background_color": "Додати колір фону",
  "template_tip": "(відображається у 3D-превʼю)",
  "color_tip": "* обраний колір відображається у 3D-прев'ю",
  "download_png" : "Завантажити png прев'ю",
  "print_price" : "Друкувати на превью підсумкову ціну",
  "price" : "Ціна ",
  "grn" : "грн ",
  "order" : "Оформити замовлення ",
  "product" : "Товар",
  "show_model": "Показати модель",
  "image_settings" : "Налаштувати зображення",
  "tex_settings" : "Налаштувати текст",
  "change_ration" : "Змінити масштаб",
  "change_angle" : "Поворот",
  "back_to_categories" : "Повернутись до категорій",
  "select_product" : "Вибрати",
  "promo" : "Промо",
  "business" : "Бізнес",
  "lux" : "Люкс",
  "add_size" : "Додати розмір",
  "total_price" : "Ціна без друку",
  "cups" : "Чашки",
  "diaries" : "Щоденники",
  "umbrellas" : "Парасолі",
  "pencils" : "Олівці",
  "pens" : "Ручки",
  "flesh_drives" : "Флешки",
  "power_bank" : "Power bank",
  "masks" : "Маски",
  "shirts" : "Футболки",
  "order_info" : "Інформація про замовлення",
  "product_name" : "Назва товару: ",
  "category" : "Категорія: ",
  "sub_category" : "Підкатегорія: ",
  "type" : "Тип: ",
  "color" : "Колір: ",
  "quantity" : "Кількість: ",
  "size" : "Розмір: ",
  "total_price_print" : "Підсумкова вартість: ",
  "total_price_print_grn" : "грн",
  "ceramic" : "Керамічні",
  "metal" : "Металеві",
  "order_form_title" : "Форма оформлення замовлення",
  "order_form_description" : "Заповніть поля нижче і наш менеджер зв'яжеться з Вами",
  "label_name" : "Ім'я",
  "label_lastname" : "Прізвище",
  "label_phone" : "Номер телефону",
  "label_address" : "Адреса доставки",
  "label_comment" : "Коментар",
  "none" : "",
  "change_product" : "Змінити продукт?",
  "change_description" : "Ваші зміни будуть втрачені",
  "change_yes" : "Так",
  "change_no" : "Ні",
  "polygraphy" : "Поліграфія",
  "orginal_link" : "Посилання на вихідний готовий дизайн",
  "print_zones" : "Зони запечатки",
  "news_search" : "Пошук по статтям",
  "move_text": "Рухайте мишку з натиснутою лівою кнопкою",
  "zoom_text": "Повертайте колесо миші",
  "wrong_img": "Пошкоджений файл",
  "max_img_size": "Максимальний дозволений розмір 5 МБ.",
  "order_success": "Ваше замовлення оформлене. Очікуйте на дзвінок менеджера",
  "required": "обов'язкове",
  "souvenirs": "Сувенірная продукція",
  "editor-works": "Работа з конструктором",
  "seles": "Продажі",
  "own-business": "Свій бізнес",
  "update_price": "Оновити ціну",
  "email_error" : "введіть діючу електронну пошту"
};
