import React from 'react';
import config from '../../config';
import NumericInput from 'react-numeric-input';
import Select from 'react-select';
import { types } from './types';
import { ReactComponent as InfoIcon } from '../../assets/info_orange.svg';
import { ReactComponent as InfoCloseIcon } from '../../assets/info_orange-close.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import './productControls.scss';

const typesMap = {};

types.forEach((cat)=>{
    typesMap[cat.value] = cat.label;
});

const sizes = [
    { value: 'XS', label: 'XS' },
    { value: 'S', label: 'S' },
    { value: 'M', label: 'M' },
    { value: 'L', label: 'L' },
    { value: 'XL', label: 'XL' },
    { value: 'XXL', label: 'XXL' },
    { value: 'XXXL', label: 'XXXL' },
];

const getCurrentCount = (array)=>{
    let count = 0;
    array.forEach((i)=>{
        count += i.count;
    });
    return count;
} 

class SizeItem extends React.Component{
    constructor(props){
        super(props);
        this.onChangeSize = this.onChangeSize.bind(this);
        this.onCountChange = this.onCountChange.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
    }
    onCountChange(e){
        this.props.onCountChange(e, this.props.index);
    }
    onChangeSize(e){
        this.props.onChangeSize(e, this.props.index);
    }
    onDeleteClick(e){
        this.props.deleteSize(parseInt(e.target.value));
    }
    render(){
        let availableSizes = [];
        if(this.props.sizes){
            this.props.sizes.forEach((size)=>{
                sizes.forEach((s)=>{
                    if(s.count){
                        if(s.size.label === size){
                            availableSizes.push(s)
                        }
                    }else{
                        if(s.label === size){
                            availableSizes.push(s)
                        }
                    }
                })
            }) 
        }
        return(
            <div className="size_item">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-5 size-number-box">
                            <NumericInput mobile className="form-control" min={1} value={this.props.count} onChange={this.onCountChange} />
                        </div>
                        {
                            this.props.hasSize && availableSizes.length > 0 &&
                            <div className="col-5 size-box">
                                <Select options={availableSizes} value={this.props.size} onChange={this.onChangeSize} isSearchable={false} />
                            </div>
                        }
                        {
                            this.props.index !== 0 &&
                            <div className="col-2 p-0 d-flex justify-content-end">
                                <div className="button_clickable">
                                    <button onClick={this.onDeleteClick} value={this.props.index}>
                                    </button>
                                    <DeleteIcon value={this.props.id}></DeleteIcon>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default class Product extends React.Component{
    constructor(props){
        super(props);
        this.state = { color: null, color_price: 0, showDescription: false, price_per_item: 0, sizes: [{ count: 1, size: sizes[0]}], colorInit : false };
        this.onColorChange = this.onColorChange.bind(this);
        this.onChangeSize = this.onChangeSize.bind(this);
        this.onCountChange = this.onCountChange.bind(this);
        this.onShowDescription = this.onShowDescription.bind(this);
        this.setProduct = this.setProduct.bind(this);
        this.calcPrice = this.calcPrice.bind(this);
        this.addSize = this.addSize.bind(this);
        this.deleteSize = this.deleteSize.bind(this);
        this.getPrice = this.getPrice.bind(this);
        this.getPrintPrice = this.getPrintPrice.bind(this);
    }
    getPrintPrice(){
        let data = this.props.data;
        let print_price = 0;
        data.templates.forEach((t)=>{
            if(t.full_print){
                print_price += t.print_price;
            }

        });
        return print_price;
    }
    getPrice(){
        let count = getCurrentCount(this.state.sizes);
        return (this.state.price_per_item * count + this.props.data.makeready_price) + (this.getPrintPrice() * count);
    }
    deleteSize(index){
        let newSizes = this.state.sizes
        for( var i = 0; i < newSizes.length; i++){
            if (i === index) { 
                newSizes.splice(i, 1); 
            }
        }
        this.setState({ sizes: newSizes });
    }
    addSize(){
        let newSizes = this.state.sizes;
        newSizes.push({ count: 1, size: sizes[0]});
        this.setState({ sizes: newSizes });
    }
    onCountChange(e, index){
        let newSizes = this.state.sizes;
        newSizes[index]['count'] = e;
        this.setState({ sizes: newSizes });
        this.calcPrice(getCurrentCount(newSizes));
    }
    onChangeSize(e,index){
        let newSizes = this.state.sizes;
        newSizes[index]['size'] = e;
        this.setState({ sizes: newSizes });
    }
    onShowDescription(){
        this.setState({ showDescription : !this.state.showDescription });
    }
    getBasePrice(count){
        let base_price = 0;
        let data = this.props.data;
        if((count > 0) && (count <= 5)){
            base_price = data.price_1_5;
        }else if((count >= 6) && (count <= 10)){
            base_price = data.price_6_10;
        }else if((count >= 11) && (count <= 50)){
            base_price = data.price_11_50;
        }else if((count >= 51) && (count <= 100)){
            base_price = data.price_51_100;
        }else if((count >= 51) && (count <= 100)){
            base_price = data.price_51_100;
        }else if((count >= 101) && (count <= 150)){
            base_price = data.price_101_150;
        }else if((count >= 151) && (count <= 200)){
            base_price = data.price_151_200;
        }else if((count >= 201) && (count <= 500)){
            base_price = data.price_201_500;
        }else if((count >= 501) && (count <= 1000)){
            base_price = data.price_501_1000;
        }else if(count > 1000){
            base_price = data.price_501_1000;
        }
        return base_price;
    }
    calcPrice(count){

        let _this = this;
        let base_price = this.getBasePrice(count);

        setTimeout(()=>{
            let price_per_item = base_price + _this.state.color_price;
            _this.setState({ price_per_item : price_per_item });
        },10);

    }
    setProduct(){
        let data = this.props.data;
        if(!this.props.current){
            data['count'] = getCurrentCount(this.state.sizes);
            data['price'] = this.state.price_per_item;
            data['size'] =   data.size !== null ? this.state.sizes : null;
            data['color'] =   this.state.color;
            data['originalSizes'] = this.state.originalSizes;
            this.props.setProduct(data);
        }else{
            let update = {};
            update['count'] =  getCurrentCount(this.state.sizes);
            update['price'] = this.state.price_per_item;
            update['size'] =   data.size !== null ? this.state.sizes : null;
            update['color'] =   this.state.color;
            this.props.updateProduct(update);
        }
    }
    componentDidMount(){ 
        if(this.props.data){
            let data = this.props.data;
            let color = data.color ? data.color : data.colors[0];
            this.setState({ color: color ? color['hex'] : null , color_price: color ? color['price'] : 0, originalSizes: data.size});
            this.calcPrice(getCurrentCount(this.state.sizes));
        }
    }

    componentDidUpdate(){
        if(!this.state.colorInit && this.props.current && this.props.current.color){
            this.setState({ color: this.props.current.color, colorInit : true });
           this.calcPrice(getCurrentCount(this.state.sizes));
        }
    }

    onColorChange(e){
        let color = this.props.data.colors.filter((c)=>{ return c['hex'] === e.target.value })[0];
        this.setState({  color: color['hex'], color_price: color['price'] });
        this.calcPrice(getCurrentCount(this.state.sizes));
        if(this.props.current){
            this.props.setColor(color['hex']);
        }
    }
    render(){

        let data = this.props.data;
        let colorButtons = data.colors.map((color, i)=>{
            return(
                <li key={i}><button onClick={this.onColorChange} value={color.hex} style={{ background: color.hex, borderColor: this.state.color === color.hex ? '#FFA400' : '#F3F4F6'}}></button></li>
            )
        });

        let sizesList = this.state.sizes.map((size, i)=>{
            return(
                <SizeItem sizes={data.sizes_o}  deleteSize={this.deleteSize} key={i} hasSize={data.sizes_o !== null} count={size.count} size={size.size} index={i} onCountChange={this.onCountChange} onChangeSize={this.onChangeSize}></SizeItem>
            )
        });

        let { translator } = this.props;

        return(
            <div className="product_item">
                <div className="info_wrapper">
                    <button onClick={this.onShowDescription}>
                        <InfoIcon></InfoIcon>
                    </button>
                    {
                        this.state.showDescription &&
                        <div className="product_description">
                            <button className="product_description-close" onClick={this.onShowDescription}>
                                <InfoCloseIcon></InfoCloseIcon>
                            </button>
                            <div dangerouslySetInnerHTML={{__html: data['description_' + translator.locale]}}></div>
                        </div>
                    }
                </div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-4 product-img-box">
                        <div className="img">
                            <img src={config.api + data.preview}></img>
                            <span className="product-title">{data['title_' + translator.locale]}</span>
                            {
                                this.state.price_per_item !== 0 &&
                                <button onClick={this.setProduct}></button>
                            }
                        </div>
                        {
                            this.state.price_per_item !== 0 &&
                            <button onClick={this.setProduct} className="select_product">{this.props.current ? translator.t('update_price') : translator.t('select_product')}</button>
                        }
                    </div>
                        <div className="col-8 product-text-box">
                            <div className="product-text-wrapper">
                                <h5 className="product-type">{translator.t(data.type)}</h5>
                                <span className="product-color-tip">{translator.t('color_tip')}</span>
                                <ul className="colors">
                                    {
                                        colorButtons
                                    }
                                </ul>
                                <div className="sizes_list">
                                    {
                                        sizesList
                                    }

                                    {
                                        data.size !== null &&
                                        <button className="add_size" onClick={this.addSize}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 3V21" stroke="#262628" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M3 12L21 12" stroke="#262628" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                            {translator.t('add_size')}
                                        </button>
                                    }

                                </div>
                            </div>
                        {
                            !this.props.hide_price &&
                            <div className="price_box">
                                    <div className="row">
                                        <div className="col-7">
                                            <p>{translator.t('total_price')}:</p>
                                        </div>
                                        <div className="col-5 price-amount">
                                            <span>{this.state.price_per_item  * getCurrentCount(this.state.sizes) + this.props.data.makeready_price} {translator.t('grn')}</span>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                    </div>
                </div>
            </div>
        )
    }
}