import React from 'react';
import { fabric } from 'fabric';
import ObjectID from "bson-objectid";
import Popup from 'reactjs-popup';
import Translator from '../../translator/translator';
import { ReactComponent as ImageIcon } from '../../assets/image.svg';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { toast } from 'react-toastify';

const translator = new Translator();

export default class ImageAdd extends React.Component{
    constructor(props){
        super(props);
        this.onImageLoad = this.onImageLoad.bind(this);
        this.onShowModal = this.onShowModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.state = { showModal: false };
    }
    onShowModal(){
        this.setState({ showModal: !this.state.showModal });
    }
    onCloseModal(){
        this.setState({ showModal: false });
    }
    onImageLoad(e){
        
        function extend(object, attr) {
            object.toObject = (function(toObject) {
              return function() {
                return fabric.util.object.extend(toObject.call(this), attr);
              };
            })(object.toObject);
        }
          
        let _this = this;

        if(e.target.files.length){
            
          let file = e.target.files[0]
          const maximumSize = 5 * 1024 * 1024;

          if(file.size > maximumSize){
            toast.error(translator.t('max_img_size'), {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
          }

          var reader = new FileReader();
          reader.onload = function() {
            fabric.Image.fromURL(reader.result,(image)=> {

                if(!image._element){
                    toast.error(translator.t('wrong_img'), {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    return;
                }

                let template = _this.props.clip;
                let clipWidth = template.width;
                let clipHeight = template.height;
                let id = ObjectID().toHexString();
                let fImage = image.set({ left: 0, top: 0 });
                fImage.centeredRotation = true;
                fImage.originX = 'center';
                fImage.originY = 'center';
                fImage.name = file.name;
                fImage.id = id;
                fImage.clipPath = _this.props.clip;

                extend(fImage, {
                    id : id,
                    isImage : true
                });

                let clipSmall = clipWidth > clipHeight ? clipHeight: clipWidth;
                let imageMax = image.width > image.height ? image.width: image.height;
                let imageSmall = image.width > image.height ? image.height: image.width;

                let aspect = (clipHeight > clipWidth) ? clipSmall / imageMax: clipSmall / imageSmall;
                fImage.scale(aspect);
                fImage.userScale = aspect;
 
                let top = template.top + (template.height / 2);
                fImage.setCoords();
                fImage.set("top",top);

                let left = template.left + (template.width / 2);
                fImage.set("left",left)

                _this.props.canvas.add(fImage);
                _this.props.canvas.setActiveObject(fImage);
                _this.onShowModal();
            });
          };
          reader.readAsDataURL(file);
        }
    }
    render(){
        return(
            <div>
                <button onClick={this.onShowModal} className="object-buttons">
                    <ImageIcon></ImageIcon>
                    <span>{ translator.t('add_image_text')}</span>
                </button>
                <Popup open={this.state.showModal} modal  position="top left" onClose={this.onCloseModal}>
                    {close => (
                        <div className="d-popup">
                            <button className="close" onClick={this.onCloseModal}>
                                <CloseIcon></CloseIcon>
                            </button>
                            <div className="d-input__wrapper">
                                <input className="d-input d-input__file" name="file" id="d-input__file" type="file" onChange={this.onImageLoad} accept="image/png, image/jpg, image/jpeg"  ></input>
                                <label className="d-input__file-button" htmlFor="d-input__file">
                                    <span className="d-input__file-button-text">{ translator.t('choose_file')}</span>
                                </label>
                            </div>

                        </div>
                    )}
                </Popup>
            </div>
        )
    }
}