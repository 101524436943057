import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Index } from './index/index';
import { Login } from './login/login';
import { Forgot } from './forgot/forgot';
import { Reset } from './reset/reset';
import { Register } from './register/register';
import { News } from './news/news';
import { Lessons } from './lessons/lessons';
import { Article } from './article/article';
import { Lesson } from './lesson/lesson';
import { Contacts } from './contacts/contacts';
import { NotFound } from './notFound/notFound';
import { ReactComponent as LanguageArrow } from './assets/language_arrow.svg';
import Editor from './editor/editor';
import Auth  from './auth';
import Translator from './translator/translator';
import Select, { components } from "react-select";
import config from './config';
import Logo from './assets/logo.png';
import 'react-toastify/dist/ReactToastify.css';
import './common.scss';
import './app.scss';

const translator = new Translator();

const  App = ()=> {

  let languages = [
    { value: 'ru', label: 'Рус' },
    { value: 'en', label: 'Eng' },
    { value: 'uk', label: 'Укр' },
  ]

  const [language, setLanguage] = useState(languages[0]);

  useEffect(()=>{

      //Sticky for header
      let header = document.querySelector('.js-header'),
          scrollPrev = 0;

      window.addEventListener('scroll', function(e) {
          let scrolled = document.documentElement.scrollTop;

          if (scrolled > scrollPrev) {
              header.classList.remove('in');
          } else {
              header.classList.add('in');
          }

          if (scrolled < 1) {
              header.classList.remove('in');
          }

          scrollPrev = scrolled;
      });

      //Set language
      languages.forEach((lang)=>{
        if(lang.value === translator.locale){
            setLanguage(lang);
        }
    });
  },[false]);

  const burgerMenu = () => {
    let menu = document.querySelector('.js-burger-menu');

    if (window.innerWidth < 1200) {
        toggleMenu();
    }

    function toggleMenu() {
        menu.classList.toggle('is-active');

        if (window.innerWidth < 1200 && menu.classList.contains('is-active')) {
            document.querySelector('body').style.overflow = 'hidden';
        } else {
            document.querySelector('body').style.overflow = 'visible';
        }
    }
  }

  const onLanguageChange = (e)=>{
      translator.setLocale(e.value);
      setLanguage(e);
      window.location.reload();
  }

  const onLogout = (e)=>{
      e.preventDefault();
      localStorage.removeItem('Token');
      window.location.reload();
  }

  const isEditor = window.location.href.indexOf('editor') > (-1);

    const CaretDownIcon = () => {
        return <LanguageArrow></LanguageArrow>;
    };

    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <CaretDownIcon />
            </components.DropdownIndicator>
        );
    };


  return (
    <div>
        {
            !isEditor &&
            <header className="header js-header" >
            <div className="header__container">
                <a className="header__logo" href="/" aria-label="Logo">
                    <img src={Logo} />
                </a>
  
                <div className="burger-menu js-burger-menu">
                    <button className="burger-menu__button js-burger-button" aria-label="Меню" onClick={burgerMenu}>
                        <span className="burger-menu__line"></span>
                        <span className="burger-menu__line"></span>
                        <span className="burger-menu__line"></span>
                    </button>
                    <div className="burger-menu__nav-box">
                        <nav className="burger-menu__nav">
                            <ul className="burger-menu__list js-burger-menu-list">
                                <li className="burger-menu__item">
                                    <a className="burger-menu__link js-burger-menu-link is-active" href="/news">{translator.t('news')}</a>
                                </li>
                                {
                                    Auth.isAuthenticated() &&
                                    <li className="burger-menu__item">
                                      <a className="burger-menu__link js-burger-menu-link" href="/education">{ translator.t('education')}</a>
                                   </li>
                                }
                                {
                                    !Auth.isAuthenticated() &&
                                    <li className="burger-menu__item">
                                      <a className="burger-menu__link js-burger-menu-link" href="https://admin.sborka.kiev.ua/login">{ translator.t('profile')}</a>
                                   </li>
                                }
                                {
                                   Auth.isAuthenticated() &&
                                   <li className="burger-menu__item">
                                      <a className="burger-menu__link js-burger-menu-link" href={`${config.admin}`}>{translator.t('profile')}</a>
                                   </li>
                                }
  
                                {
                                    Auth.isAuthenticated() &&
                                    <li className="burger-menu__item">
                                       <a className="burger-menu__link js-burger-menu-link" href="/contacts">{translator.t('contacts')}</a>
                                    </li>
                                }
                            </ul>

                            <div className="language">
                                <Select value={language} options={languages} isSearchable={false} onChange={onLanguageChange} components={{ DropdownIndicator }}></Select>
                            </div>

                            {
                                !Auth.isAuthenticated() &&
                                <a className="s-btn s-btn_black s-btn_header" href="/login">{translator.t('autorization')}</a>
                            }
                            {
                                Auth.isAuthenticated() &&
                                <a className="s-btn s-btn_black s-btn_header" href="#" onClick={onLogout}>{translator.t('logout')}</a>
                            }
                        </nav>
                    </div>
                </div>
            </div>
        </header>
        }
      
        <Router>
          <Switch>
             <Route component={Reset}  path="/reset/:id"></Route>
             <Route component={Forgot}  path="/forgot"></Route>
             <Route component={Login}  path="/login"></Route>
             <Route component={Register}  path="/register"></Route>
             <Route component={Article}  path="/news/:id"></Route>
             <Route component={News}  path="/news"></Route>
             <Route component={Lesson}  path="/education/:id"></Route>
             <Route component={Lessons}  path="/education"></Route>
             <Route component={Contacts}  path="/contacts"></Route>
             <Route path="/editor/:manager/:lang" render={(props) => (
                    <Editor {...props} own={false} />
             )}/>
             <Route component={Index}  path="/"></Route>
             <Route component={NotFound} path="/*"></Route>
          </Switch>
        </Router>
    </div>
  );
}

export default App;
