import React, { useEffect } from 'react';
import { Footer } from '../footer/footer';
import Translator from '../translator/translator';
import config from '../config';
import axios from "axios";
import formatDate  from '../date';

const translator = new Translator();

export const Lesson = ({ match })=>{
    
    const [data, setData] = React.useState(null);

    const fetch = ()=>{
        axios.get(`${config.api}site/get-lesson-page?id=${match.params.id}`)
        .then(res => {
            let responseData = res.data;
            setData(responseData);
        })
    };

    useEffect(()=>{

        fetch();

    },[false]);

   
    return(
        <div>
        {
            data && 
            <main> 
                <section class="s-block s-block_top article">
                    <div class="article__container">
                        <a class="s-btn s-btn_back article__back-btn" type="button" href="/education">
                            <svg class="s-btn__back-arrow" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.71875 5L3.00168 12L9.71875 19" stroke="#262628" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round"/>
                                <line x1="1" y1="-1" x2="16.7331" y2="-1" transform="matrix(1 0 0 -1 3.26562 11.0317)"
                                    stroke="#262628" stroke-width="2" stroke-linecap="round"/>
                            </svg>
                            <span>{ translator.t('back_education')}</span>
                        </a>
                        <article class="article__template">
                            <p class="s-text-small s-text-small_date s-text-small_date_alt s-text-small_text-center">{formatDate(data.article.createdAt)}</p>
                            <p class="s-text-small s-text-small_label s-text-small_text-center article__label">{translator.t(data.article.category)}</p>
                            <h2 class="s-template-header article__title">
                                {
                                    data.article.title
                                }
                            </h2>
                            <div class="article__img-container">
                                <div class="s-img-box s-img-box_middle article__img-box">
                                    <img class="s-img" src={config.api + data.article.image} alt="Изображение"/>
                                </div>
                            </div>
                            <div class="article__text-container">
                                <div dangerouslySetInnerHTML={{__html: data.article.content }}></div>
                            </div>
                        </article>
                    </div>
                </section>

            </main>
        }
        {
            data &&
            <Footer footer={data.footer}  translator={translator} ></Footer>
        }
        </div>
    );
}