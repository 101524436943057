import React from 'react';
import { Formik } from "formik";
import axios from "axios";
import config from '../config';
import * as Yup from "yup";
import { ToastContainer, toast } from 'react-toastify';

export const Call = ({ translator }) => {


    return (
        <div className="contacts__form-box">
                <ToastContainer></ToastContainer>
                <h2 className="s-add-header contacts__header">{translator.t('recall')}</h2>
                 
                  <Formik
                        initialValues={{ name: "", phone: ""}}
                        onSubmit={( values,{ resetForm }) =>  {
                            axios.post(`${config.api}site/request-call`, values)
                            .then(res => {
                                resetForm();
                                toast.success(translator.t('sent'), {
                                    position: toast.POSITION.TOP_RIGHT
                                });
                            }).catch((err => {
                                toast.error(translator.t('sent_error'), {
                                    position: toast.POSITION.TOP_RIGHT
                                });
                            }));
                        }}
                        validationSchema={Yup.object().shape({
                            name: Yup.string().required(translator.t('required')),
                            phone: Yup.string().required(translator.t('required'))
                        })}
                    >
                        {props => {
                            const {
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                                handleSubmit
                            } = props;
                            return (
                                <form onSubmit={handleSubmit}  className="s-form-box__form" >
                                    <div className="s-form-box__input-box">
                                        <label className="s-form-box__label" >{translator.t('client_name')}</label>
                                        <input
                                            type="text"
                                            value={values.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="name"
                                            placeholder={translator.t('type_name')}
                                            className="s-form-box__input first__input"
                                        />
                                        {errors.name && touched.name && (
                                            <div className="form_error">{errors.name}</div>
                                        )}
                                     </div>
                                     <div className="s-form-box__input-box">
                                        <label className="s-form-box__label" >{translator.t('phone')}</label>
                                        <input
                                            type="text"
                                            value={values.phone}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="phone"
                                            placeholder={translator.t('type_phone')}
                                            className="s-form-box__input first__input"
                                        />
                                        {errors.phone && touched.phone && (
                                            <div className="form_error">{errors.phone}</div>
                                        )}
                                    </div>
                                    <div className="s-form-box__input-box">
                                        <button className="s-btn s-btn_narrow s-btn_yellow footer__btn" type="submit">{translator.t('send')}
                                            <svg className="footer__submit-icon" width="26" height="26" viewBox="0 0 26 26" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1 6.33349C1 5.22892 1.89543 4.3335 3 4.3335H23C24.1046 4.3335 25 5.22893 25 6.3335V20.7502C25 21.8547 24.1046 22.7502 23 22.7502H3C1.89543 22.7502 1 21.8547 1 20.7502V6.33349Z"
                                                    stroke="white" strokeWidth="2" strokeLinejoin="round"/>
                                                <path d="M2.29114 5.61567C1.81613 5.14396 2.15018 4.3335 2.81962 4.3335H23.1804C23.8498 4.3335 24.1839 5.14396 23.7089 5.61567L15.1139 14.1509C13.9441 15.3127 12.0559 15.3127 10.8861 14.1509L2.29114 5.61567Z"
                                                    stroke="white" strokeWidth="2" strokeLinejoin="round"/>
                                            </svg>
                                        </button>
                                    </div>

   
                            </form>
                            );
                        }}
                    </Formik>
  
        </div>
      
    )
}


