const Auth = {
    isAuthenticated: () => {
        const token = localStorage.getItem('Token');
        if (token) {
            return true;
        }
        return false;
    },
    getToken: () => {
        const token = localStorage.getItem('Token');
        if (token) {
            return token;
        }
    },
    getProfile: () => {
        const profile = localStorage.getItem('profile');
        if (profile) {
            return profile;
        }
    },
}
export default Auth;