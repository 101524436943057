import React,{ useEffect } from 'react';
import { Formik , Field } from "formik";
import axios from "axios";
import config from '../config';
import Translator from '../translator/translator';
import { Footer } from '../footer/footer';
import * as Yup from "yup";
import { ToastContainer, toast } from 'react-toastify';

const translator = new Translator();

export const Forgot = ()=>{

    const [data, setData] = React.useState(null);

    const fetch = ()=>{
        axios.get(`${config.api}site/get-index?lang=${translator.locale}`)
        .then(res => {
            let responseData = res.data;
            setData(responseData);
        })
    };

    useEffect(()=>{

        fetch();

    }, [])

    return(
        <main>
          <section className="s-block_authorization s-block_bottom authorization">
                <ToastContainer></ToastContainer>
                <div className="s-container">
                <div className="s-form-box">
                    <h3 className="s-add-header s-add-header_text-center first__form-title">{translator.t('forgot')}</h3>
                    <div className="login_block">
                        <div className="modal_block">
                            
                            <Formik
                                    initialValues={{ email: "" }}
                                    onSubmit={values => {
                                        axios.post(`${config.api}managers/forgot-password`, values)
                                        .then(res => {
                                            toast.success(translator.t('reset_link_sent'), {
                                                position: toast.POSITION.TOP_RIGHT
                                            });
                                        }).catch((err => {
                                            toast.error(translator.t('user_not_exists'), {
                                                position: toast.POSITION.TOP_RIGHT
                                            });
                                        }));
                                    }}
                                    validationSchema={Yup.object().shape({
                                        email: Yup.string().email().required(translator.t('required'))
                                    })}
                                >
                                    {props => {
                                        const {
                                            values,
                                            touched,
                                            errors,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit
                                        } = props;
                                        return (
                                            <form onSubmit={handleSubmit}  className="s-form-box__form" >
                                                <div className="s-form-box__input-box">
                                                    <label className="s-form-box__label" >{translator.t('email')}</label>
                                                    <input
                                                        type="text"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        name="email"
                                                        placeholder={translator.t('type_email')}
                                                        className="s-form-box__input first__input"
                                                    />
                                                    {errors.email && touched.email && (
                                                        <div className="form_error">{errors.email}</div>
                                                    )}
                                                </div>
                                                <div className="s-form-box__input-box">
                                                    <button className="s-btn s-btn_narrow s-btn_black s-form-box__btn first__submit">{translator.t('send')}</button>
                                                </div>

                                        </form>
                                        );
                                    }}
                                </Formik>
                        </div>
                        </div>       
                </div>
                </div>
        </section>
    </main>
    )
}

export default Forgot;