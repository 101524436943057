export default {
  "news": "Новости",
  "education": "Обучение",
  "profile": "Личный кабинет",
  "contacts": "Контакты",
  "autorization": "Авторизация",
  "login": "Войти",
  "email": "Почта",
  "password": "Пароль (мин. 8 символов)",
  "confirm_password": "Подтвердите пароль",
  "all_news": "Все новости",
  "read_more": "Читать статью",
  "address": "Адрес",
  "phone": "Телефон",
  "write_to_typography": "Написать в типографию",
  "client_name": "Имя",
  "client_phone": "Номер телефона владельца аккаунта",
  "copyrights": "Sborka-3D",
  "developers": "Разработка и поддержка:",
  "send": "Отправить",
  "typogrphy": "Типография Ультрадрук",
  "adds": "Мир рекламы",
  "souvenir": "Сувениры и полиграфия",
  "type_email": "Написать почту",
  "type_password": "Написать пароль",
  "type_name": "Написать имя",
  "type_phone": "Написать телефон",
  "type_comment": "Написать коментарии",
  "forgot": "Забыли пароль?",
  "not_robor": "Я не робот",
  "ideas": "Идеи печати",
  "employees": "Сотрудникам",
  "selected_clients": "Избранным клиентам",
  "mass_distrubution": "Массовое распространение",
  "to_leaders": "Руководителям",
  "for_partners": "Партнерам",
  "for_family": "Семье",
  "for_friends": "Друзьям",
  "register": "Регистрация",
  "name": "Имя",
  "company": "Название компании",
  "all": "Все",
  "back_news": "Вернуться к новостям",
  "back_education": "Вернуться к обучению",
  "recall": "Заказать обратный звонок",
  "write_support": "Напишите в нашу службу поддержки",
  "comment": "Комментарий",
  "delivery": "Информация о доставке",
  "step": "Шаг",
  "required": "Обязательно",
  "must_check": "Поле должно быть отмечено ",
  "sent": "Отправлено",
  "must_match": "Пароли должны совпадать",
  "sent_error": "Ошибка при отправке",
  "autorization_error": "Ошибка авторизации",
  "last_news": "Последние новости",
  "logout": "Выход",
  "register_success": "Ожидайте звонок от администратора",
  "user_exist": "Пользователь с таким email уже существует",
  "user_not_exists": "Пользователя с таким email не существует",
  "reset_link_sent": "Письмо со ссылкой отправлено на почту",
  "password_reset_success": "Новый пароль отправлен Вам на почту",
  "password_reset_fail": "сбой сброса пароля",
  "lorem": "Вы знали, что победа на выборах на 80% зависит от успеха предвыборной агитации? Чем выигрышнее дизайн полиграфии и точнее подобраны триггеры, тем выше шанс завоевать внимание избирателя! При этом, каждый кандидат в депутаты должен знать: изготавливать агитационные материалы разрешено только в полном соответствии с действующим законодательством Украины.",
  "image_reqirments" : "Требования к изображениям",
  "image_reqirments_title" : "Требования к изображению",
  "image_reqirments_description" : "Можно загружать картинки в PNG. Максимальный размер 5 МБ. Загрузка больших изображений может занимать время.",
  "image_reqirments_link" : "Скачать требования к изображениям",
  "show_grid_top" : "Отобразить сетку",
  "show_grid_bottom" : "1 квадрат = 10х10mm",
  "show_grid_on" : "Вкл",
  "show_grid_off" : "Выкл",
  "add_image_text" : "Добавить изображение",
  "add_size" : "Добавить размер",
  "choose_file": "Выбрать файл",
  "choose_product": "Выберите товар",
  "add_background_color": "Добавить цвет фона",
  "template_tip": "(отображается в 3D-превью)",
  "color_tip": "* выбранный цвет отображается в 3D-превью",
  "add_text_text" : "Добавить текст",
  "download_png" : "Скачать png превью",
  "print_price" : "Печатать на превью итоговую цену ",
  "price" : "Цена ",
  "grn" : "грн ",
  "order" : "Оформить заказ ",
  "product" : "Товар",
  "show_model": "Показать модель",
  "image_settings" : "Настроить изображение",
  "tex_settings" : "Настроить текст",
  "change_ration" : "Изменить масштаб",
  "change_angle" : "Поворот",
  "back_to_categories" : "Вернуться к категориям",
  "select_product" : "Выбрать",
  "promo" : "Промо",
  "business" : "Бизнес",
  "lux" : "Люкс",
  "total_price" : "Цена без печати",
  "cups" : "Чашки",
  "diaries" : "Ежедневники",
  "umbrellas" : "Зонты",
  "pencils" : "Карандаши",
  "pens" : "Ручки",
  "flesh_drives" : "Флешки",
  "power_bank" : "Power bank",
  "masks" : "Маски",
  "shirts" : "Футболки",
  "order_info" : "Информация о заказе",
  "product_name" : "Название товара: ",
  "category" : "Категория: ",
  "sub_category" : "Подкатегория: ",
  "type" : "Тип: ",
  "color" : "Цвет: ",
  "quantity" : "Количество: ",
  "size" : "Размер: ",
  "total_price_print" : "Итоговая стоимость: ",
  "total_price_print_grn" : "грн",
  "ceramic" : "Керамические",
  "metal" : "Металические",
  "order_form_title" : "Форма оформления заказа",
  "order_form_description" : "Заполните поля ниже и наш менеджер свяжется с Вами",
  "label_name" : "Имя",
  "label_lastname" : "Фамилия",
  "label_phone" : "Номер телефона",
  "label_address" : "Адрес доставки",
  "label_comment" : "Комментарий",
  "none" : "",
  "change_product" : "Сменить продукт?",
  "change_description" : "Ваши изменения будут утеряны",
  "change_yes" : "Да",
  "change_no" : "Нет",
  "polygraphy" : "Полиграфия",
  "orginal_link" : "Ссылка на исходник готового дизайна",
  "print_zones" : "Зоны запечатки",
  "news_search" : "Поиск по статьям",
  "move_text": "Двигайте мышь с нажатой левой кнопкой",
  "zoom_text": "Вращайте колесо мыши",
  "wrong_img": "Поврежденный файл",
  "max_img_size": "Максимальный допустимый размер 5 МБ.",
  "order_success": "Ваш заказ оформлен. Ожидайте звонка менеджера",
  "required": "обязательное",
  "souvenirs": "Сувенирная продукция",
  "editor-works": "Работа с конструктором",
  "seles": "Продажи",
  "own-business": "Свой бизнес",
  "update_price": "Обновить цену",
  "email_error": "введите действующий email"
};
