let isProd = true; 
const dev = {
    api: "http://localhost:4000/",
    admin: "http://localhost:3000/",
    editor: "http://localhost:3001/editor/61375f19a90d433392252195",
}
const prod = {
    api: "https://admin.sborka.kiev.ua/api/",
    admin: "https://admin.sborka.kiev.ua/",
    editor: "https://admin.sborka.kiev.ua/editor/61375f19a90d433392252195",
}
export default isProd ? prod : dev;