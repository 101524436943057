import React, { useState} from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { ReactComponent as MailIcon } from '../../assets/mail.svg';

const Order = ({ onOrderClick, translator }) => {

    const [disbled, setDisabled] = useState(false);
    return(
             <div className="b-popup b-popup_order">
                <h2 className="b-popup__title">{translator.t('order_form_title')}</h2>
                <p className="b-popup__text">{translator.t('order_form_description')}</p>
                <Formik
                    initialValues={{ 
                        firstname: "",
                        lastname:"",
                        phone: "",
                        email: "",
                        address: "",
                        comment: "",
                        company: "",
                        link: "",
                        
                    }}
                    onSubmit={values => {
                        setDisabled(true);
                        onOrderClick(values);
                    }}
                    validationSchema={Yup.object().shape({
                        firstname: Yup.string().required(translator.t('required')),
                        email: Yup.string().email().required(translator.t('required'))
                    })}
                    >
                        {props => {
                            const {
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                                handleSubmit
                            } = props;
                            return (
                                <form onSubmit={handleSubmit} className="b-form">
                                    <div className="b-form__columns">
                                        <div className="form-box">
                                            <div className="form_group">
                                                <label>{translator.t('label_name')}* </label>
                                                <input
                                                    type="text"
                                                    value={values.firstname}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="firstname"
                                                    placeholder=""
                                                    className={
                                                        errors.firstname && touched.firstname
                                                        ? "error"
                                                        : ""
                                                    }
                                                />
                                                {errors.firstname && touched.firstname && (
                                                    <div className="form_error">{errors.firstname}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>{translator.t('label_phone')} </label>
                                                <input
                                                    type="text"
                                                    value={values.phone}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="phone"
                                                    placeholder=""
                                                    className={
                                                        errors.phone && touched.phone
                                                        ? "error"
                                                        : ""
                                                    }
                                                />
                                                {errors.phone && touched.phone && (
                                                    <div className="form_error">{errors.phone}</div>
                                                )}
                                            </div>
                                            {/* <div className="form_group">
                                                <label>{translator.t('label_address')}</label>
                                                <input
                                                    type="text"
                                                    value={values.address}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="address"
                                                    placeholder=""
                                                    className={
                                                        errors.address && touched.address
                                                        ? "error"
                                                        : ""
                                                    }
                                                />
                                                {errors.address && touched.address && (
                                                    <div className="form_error">{errors.address}</div>
                                                )}
                                            </div> */}
                                        </div>
                                        <div className="form-box">
                                            <div className="form_group">
                                                <label>{translator.t('label_lastname')}</label>
                                                <input
                                                    type="text"
                                                    value={values.lastname}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="lastname"
                                                    placeholder=""
                                                    className={
                                                        errors.lastname && touched.lastname
                                                        ? "error"
                                                        : ""
                                                    }
                                                />
                                                {errors.lastname && touched.lastname && (
                                                    <div className="form_error">{errors.lastname}</div>
                                                )}
                                            </div>
                                            <div className="form_group">
                                                <label>E-mail* </label>
                                                <input
                                                    type="text"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="email"
                                                    placeholder=""
                                                    className={
                                                        errors.email && touched.email
                                                        ? "error"
                                                        : ""
                                                    }
                                                />
                                                {errors.email && touched.email && (
                                                    <div className="form_error">{errors.email}</div>
                                                )}
                                            </div>
                                            {/* <div className="form_group">
                                                <label>Company </label>
                                                <input
                                                    type="text"
                                                    value={values.company}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="company"
                                                    placeholder=""
                                                    className={
                                                        errors.company && touched.company
                                                        ? "text-input error b-form__input"
                                                        : "text-input b-form__input"
                                                    }
                                                />
                                                {errors.company && touched.company && (
                                                    <div className="form_error">{errors.company}</div>
                                                )}
                                            </div> */}
                                        {/* <div className="col-12">
                                            <div className="form_group">
                                                <label>{translator.t('label_comment')}</label>
                                                <textarea
                                                    value={values.comment}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="comment"
                                                    placeholder=""
                                                    className={
                                                        errors.comment && touched.comment
                                                        ? "text-input error b-form__input"
                                                        : "text-input b-form__input"
                                                    }
                                                ></textarea>
                                                {errors.comment && touched.comment && (
                                                    <div className="form_error">{errors.comment}</div>
                                                )}
                                            </div>
                                        </div> */}
                                        </div>
                                    </div>
                                    <div className="form_group">
                                        <label>{translator.t('orginal_link')}</label>
                                        <input
                                            type="text"
                                            value={values.link}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="link"
                                            placeholder=""
                                            className={
                                                errors.link && touched.link
                                                ? "b-form__input-wide error"
                                                : "b-form__input-wide"
                                            }
                                        />
                                        {errors.link && touched.link && (
                                            <div className="form_error">{errors.link}</div>
                                        )}
                                    </div>
                                    <div className="b-form__btn-box">
                                        <button disabled={disbled} className="b-btn">{translator.t('send')}<MailIcon></MailIcon></button>
                                    </div>
                                </form>
                            );  
                        }}
                    </Formik>
            </div>
    )
}

export default Order;