import React from 'react';
import config from '../../config';
import Product from './product';
import { fabric } from 'fabric';
import { ReactComponent as BackIcon } from '../../assets/back.svg';
import { ReactComponent as CloseIcon } from '../../assets/close.svg';
import { subcategories } from './categories';
import { types } from './types';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './productControls.scss';
const axios = require('axios');

export default class ProductControls extends React.Component{

    constructor(props){
        super(props);
        this.onCategoryClick = this.onCategoryClick.bind(this);
        this.fetch = this.fetch.bind(this);
        this.getCategories = this.getCategories.bind(this);
        this.onCloseProducts = this.onCloseProducts.bind(this);
        this.setProduct = this.setProduct.bind(this);
        this.state = { category: null, products: [], categories: [], product: null };
    }

    setProduct(product){
        this.props.setProduct(product);
        this.props.setCurrentProduct(product);
        if(window.innerWidth < 850){
            document.getElementById('show_model').click();
        }
    }

    componentDidMount(){
        if(!this.props.product){
            this.getCategories();
        }else{
            this.setState({ product: this.props.product });
        }
    }

    getCategories(){
        let _this = this;
        axios.get(`${config.api}products/categories`)
        .then(res => {
            let responseData = res.data;
            _this.setState({ categories: responseData});
        });
    }
    
    fetch(category){

        const _this = this;
        axios.get(`${config.api}products/editor-products?category=${category}`).then((res) => {

            let data = res.data;

            let products = data.map((product)=>{

                let templates = [];

                product.model =  config.api + product.model;

                _this.state.categories.forEach((cat)=>{
                    if(cat.id === product.category){
                        product['category_name'] = cat.name;
                    }
                });

                subcategories.forEach((cat)=>{
                    if(cat.value === product.sub_category){
                        product['sub_category_name'] = cat.label;
                    }
                });

                types.forEach((cat)=>{
                    if(cat.value === product.type){
                        product['type_name'] = cat.label;
                    }
                });
                
                product.templates.forEach((temp)=>{
                    templates.push({
                        "_id": temp._id,
                        "surface": temp.surface,
                        "model": config.api + temp.model,
                        "clip": new fabric.Rect({ width: temp.clip_width, height: temp.clip_height, top: temp.clip_offset_y, left: temp.clip_offset_x , absolutePositioned: true }),
                        "img" : config.api + temp.editor_background,
                        "img_original" : temp.editor_background,
                        "fill" : temp.fill,
                        "w": temp.real_width,
                        "h": temp.real_height,
                        "full_print": temp.full_print,
                        "print_price": temp.print_price,
                        "name_uk": temp.name_uk,
                        "name_en": temp.name_en,
                        "name_ru": temp.name_ru,
                    });
                });

                product.templates = templates;
                product.sizes_o = product.size;

                return product;
            });

            _this.setState({ products: products });

        }
        ).catch((err => {
            console.log(err);
        }));

    }
    onCategoryClick(e){
        this.setState({ category: e.target.value });
        this.fetch(e.target.value);
    }
    onCloseProducts(){
        
        let { translator } = this.props;

        if(this.props.product){
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className="popup-content">
                            <div className="b-popup b-popup_img">
                                <button className="close">
                                    <CloseIcon onClick={() => {onClose();}}></CloseIcon>
                                </button>
                                <h2 className="b-popup__title">{translator.t('change_product')}</h2>
                                <p className="b-popup__text">{translator.t('change_description')}</p>
                                <div className="b-popup__btns"> 
                                    <button className="b-popup__btn" onClick={() =>{
                                        this.setState({ category: null, products: [], product: null });
                                        this.props.deleteCurrentProduct();
                                        this.props.deleteProduct();
                                        this.getCategories();
                                        onClose();
                                        }}
                                    >
                                        {translator.t('change_yes')}
                                    </button>
                                    <button className="b-popup__btn"
                                        onClick={() => {
                                            onClose();
                                        }}
                                    >
                                    {translator.t('change_no')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    );
                }
            });
        }else{
            this.setState({ category: null, products: [], product: null });
            this.props.deleteCurrentProduct();
            this.getCategories();
        }
    }
    render(){

        let { translator } = this.props;

        let list = this.state.categories.map((category, i)=>{
            return(
                <li key={i}>
                     <div className="category_item">
                         <div className="category_img" style={{ background: `url(${config.api + category.img}) center center no-repeat`, backgroundSize: 'contain'}} ></div>
                        <h6>{translator.t(category.id)}</h6>
                        <button onClick={this.onCategoryClick} value={category.id}></button>
                     </div>
                </li>
            )
        });
        
        let prods = this.props.product ? [this.props.product] :  this.state.products;

        let products = prods.map((product, i)=>{
            return(
                <Product setColor={this.props.setColor} translator={translator} hide_price={this.props.hide_price} setProduct={this.setProduct} key={product._id} data={product} current={this.props.product} updateProduct={this.props.updateProduct}></Product>
            )
        });

        return(
            <div className="product-list">
                {
                    ((this.state.products.length > 0) || (this.state.product)) &&
                    <div  className="product_controls">
                        <button className="back_btn" onClick={this.onCloseProducts}><BackIcon></BackIcon> { translator.t('back_to_categories')}</button>
                        <button className="close-btn close" onClick={this.onCloseProducts}><CloseIcon></CloseIcon> { translator.t('back_to_categories')}</button>
                    </div>
                }
                {
                    ((this.state.products.length > 0)) &&
                       products
                }
                {
                    this.state.products.length === 0 &&
                    <ul className="category_list">
                        {list}
                    </ul>
                }
            </div>
        )
    }
}