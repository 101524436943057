import React,{ useState, useEffect } from 'react';
import { Formik , Field } from "formik";
import axios from "axios";
import config from '../config';
import Translator from '../translator/translator';
import * as Yup from "yup";
import { ReactComponent as HideIcon } from '../assets/Hide.svg';
import { ReactComponent as ViewIcon } from '../assets/View.svg';
import "./register.css";

const equalTo = (ref, msg) => {
    return Yup.mixed().test({
        name: 'equalTo',
        exclusive: false,
        message: msg,
        params: {
            reference: ref.path,
        },
        test: function (value) {
            return value === this.resolve(ref);
        },
    });
}

Yup.addMethod(Yup.string, 'equalTo', equalTo);

const translator = new Translator();

export const Register = ()=>{

    const [data, setData] = React.useState(null);
    const [showPassword, setShowpassword] = React.useState(false);
    const [successMessage, setSuccessMessage] = useState(null);

    const onShowPasswordClick = (e)=>{
        setShowpassword(!showPassword);
    }

    const fetch = ()=>{
        axios.get(`${config.api}site/get-index?lang=${translator.locale}`)
        .then(res => {
            let responseData = res.data;
            setData(responseData);
        })
    };

    useEffect(()=>{

        fetch();

    }, [])

    const [errorMessage, setErrorMessage] = useState(null);
    return(
        <main>
          <section class="s-block_authorization s-block_bottom authorization">
                <div class="s-container">
                    <div className="s-form-box">
                        <h3 className="s-add-header s-add-header_text-center first__form-title">{translator.t('register')}</h3>
                        {
                            successMessage &&
                            <h4 className="text-center">{successMessage}</h4>
                        }
                        {
                            !successMessage &&
                            
                            <div className="login_block">
                               <div className="modal_block">
                                
                                <Formik
                                        initialValues={{ firstname: "", phone: "", email: "", password: "", confirm_password: "" , not_robot: false }}
                                        onSubmit={values => {
                                            values.email2 = values.email;
                                            values.file_save_days = 30;
                                            values.allow_iframe = true; 
                                            values.bonus = 0;
                                            values.role = 'manager';
                                            values.lastname = '-';
                                            values.delivery_address = '-';

                                            axios.post(`${config.api}managers/create`, values)
                                            .then(res => {
                                                setSuccessMessage(translator.t('register_success'));
                                            }).catch((err => {
                                                if((err.response) && (err.response.data === 'user with same email exist')){
                                                    setErrorMessage(translator.t('user_exist'));
                                                }else{
                                                    setErrorMessage("Error");
                                                }
                                            }));
                                        }}
                                        validationSchema={Yup.object().shape({
                                            firstname: Yup.string().required(translator.t('required')),
                                            phone: Yup.string().required(translator.t('required')),
                                            email: Yup.string().email(translator.t('email_error')).required(translator.t('required')),
                                            password: Yup.string().required(translator.t('required')),
                                            confirm_password: Yup.string().equalTo(Yup.ref('password'), translator.t('must_match')).required(translator.t('required')),
                                            not_robot: Yup.bool().oneOf([true], translator.t('must_check'))
                                        })}
                                    >
                                        {props => {
                                            const {
                                                values,
                                                touched,
                                                errors,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit
                                            } = props;
                                            return (
                                                <form onSubmit={handleSubmit}  className="s-form-box__form" >
                                                    <div className="s-form-box__input-box">
                                                        <label className="s-form-box__label" >{translator.t('name')}*</label>
                                                        <input
                                                            type="text"
                                                            value={values.firstname}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            name="firstname"
                                                            placeholder={translator.t('type_name')}
                                                            className="s-form-box__input first__input"
                                                        />
                                                        {errors.firstname && touched.firstname && (
                                                            <div className="form_error">{errors.firstname}</div>
                                                        )}
                                                    </div>
                                                    <div className="s-form-box__input-box">
                                                        <label className="s-form-box__label" >{translator.t('phone')}*</label>
                                                        <input
                                                            type="text"
                                                            value={values.phone}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            name="phone"
                                                            placeholder={translator.t('type_phone')}
                                                            className="s-form-box__input first__input"
                                                        />
                                                        {errors.phone && touched.phone && (
                                                            <div className="form_error">{errors.phone}</div>
                                                        )}
                                                    </div>
                                                    <div className="s-form-box__input-box">
                                                        <label className="s-form-box__label" >{translator.t('email')}*</label>
                                                        <input
                                                            type="text"
                                                            value={values.email}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            name="email"
                                                            placeholder={translator.t('type_email')}
                                                            className="s-form-box__input first__input"
                                                        />
                                                        {errors.email && touched.email && (
                                                            <div className="form_error">{errors.email}</div>
                                                        )}
                                                    </div>
                                                    <div className="s-form-box__input-box">
                                                        <label className="s-form-box__label" >{translator.t('password')}*</label>
                                                        <div>
                                                            <input
                                                                type={ showPassword? 'text': 'password'}
                                                                value={values.password}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                name="password"
                                                                placeholder={translator.t('type_password')}
                                                                className="s-form-box__input first__input"
                                                                minlength="8"
                                                            />
                                                            <div className="eye" onClick={onShowPasswordClick}>

                                                                {
                                                                    showPassword &&
                                                                    <ViewIcon></ViewIcon>
                                                                }
                                                                {
                                                                    !showPassword &&
                                                                    <HideIcon></HideIcon>
                                                                }
                                                            </div>
                                                        </div>
                                                        {errors.password && touched.password && (
                                                            <div className="form_error">{errors.password}</div>
                                                        )}
                                                    </div>
                                                    <div className="s-form-box__input-box">
                                                        <label className="s-form-box__label" >{translator.t('confirm_password')}*</label>
                                                        <input
                                                            type="password"
                                                            type={ showPassword? 'text': 'password'}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            name="confirm_password"
                                                            placeholder={translator.t('type_password')}
                                                            className="s-form-box__input first__input"
                                                            minlength="8"
                                                        />
                                                        {errors.confirm_password && touched.confirm_password && (
                                                            <div className="form_error">{errors.confirm_password}</div>
                                                        )}
                                                    </div>
                                                    <div className="s-form-box__add-box">
                                                        <label className="s-form-box__check-label s-form-box__check-label_fl-start">
                                                            <Field type="checkbox" name="not_robot" className="s-form-box__checkbox" />
                                                            <span className="s-form-box__custom-checkbox"></span>
                                                            {translator.t('not_robor')}
                                                        </label>
                                                        {errors.not_robot && touched.not_robot && (
                                                            <div className="form_error">{errors.not_robot}</div>
                                                        )}
                                                    </div>
                                                    <div className="s-form-box__input-box">
                                                        <button className="s-btn s-btn_narrow s-btn_black s-form-box__btn first__submit">{translator.t('login')}</button>
                                                    </div>
                                                    {
                                                        errorMessage &&
                                                        <div className="s-form-box__input-box">
                                                            <div className="form_error">{errorMessage}</div>
                                                        </div>
                                                    }

                                            </form>
                                            );
                                        }}
                                    </Formik>
                               </div>
                            </div>  
                        }

                    </div>
                </div>
        </section>
    </main>
    )
}

export default Register;