import React, { useEffect } from 'react';
import { Footer } from '../footer/footer';
import Translator from '../translator/translator';
import config from '../config';
import axios from "axios";
import formatDate  from '../date';
import cutText  from '../text';

const translator = new Translator();

export const Article = ({ match })=>{

    const [data, setData] = React.useState(null);

    const fetch = ()=>{
        axios.get(`${config.api}site/get-article-page?id=${match.params.id}&lang=${translator.locale}`)
        .then(res => {
            let responseData = res.data;
            setData(responseData);
        })
    };

    useEffect(()=>{

        fetch();

    },[false])

    let newsList = data ? data.last.map((element, i)=>{
        
        let date = new Date(element.createdAt);
        let dateString = formatDate(date);

        return(
                           <li className="last-news__item" key={i}>
                                <a className="last-news__link" href={`/news/${element._id}`} aria-label="Последняя новость">
                                    <article className="last-news__article">
                                        <div className="s-img-box s-img-box_square last-news__img-box">
                                            {
                                                element.image &&
                                                <img className="s-img" src={config.api + element.image} alt="Новость"/>
                                            }
                                        </div>
                                        <p className="s-text-small s-text-small_date">{dateString}</p>
                                        <p className="s-text-small s-text-small_label">{translator.t(element.category)}</p>
                                        <h3 className="s-article-header">{element.title}</h3>
                                        <div className="s-text-reg news__text" dangerouslySetInnerHTML={{__html: cutText(element.content, 500)}}></div>
                                    </article>
                                </a>
                            </li>
        )
    }): [];

   
    return(
        <div>
        {
            data && 
            <main> 
                <section className="s-block s-block_top article">
                    <div className="article__container">
                        <a className="s-btn s-btn_back article__back-btn" type="button" href="/news">
                            <svg className="s-btn__back-arrow" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.71875 5L3.00168 12L9.71875 19" stroke="#262628" strokeWidth="2" strokeLinecap="round"
                                    strokeLinejoin="round"/>
                                <line x1="1" y1="-1" x2="16.7331" y2="-1" transform="matrix(1 0 0 -1 3.26562 11.0317)"
                                    stroke="#262628" strokeWidth="2" strokeLinecap="round"/>
                            </svg>
                            <span>{ translator.t('back_news')}</span>
                        </a>
                        <article className="article__template">
                            <p className="s-text-small s-text-small_date s-text-small_date_alt s-text-small_text-center">{formatDate(data.article.createdAt)}</p>
                            <p className="s-text-small s-text-small_label s-text-small_text-center article__label">{translator.t(data.article.category)}</p>
                            <h2 className="s-template-header article__title">
                                {
                                    data.article.title
                                }
                            </h2>
                            <div className="article__img-container">
                                <div className="s-img-box s-img-box_middle article__img-box">
                                    <img className="s-img" src={config.api + data.article.image} alt="Изображение"/>
                                </div>
                            </div>
                            <div className="article__text-container">
                                <div className="s-text-reg news__text" dangerouslySetInnerHTML={{__html: data.article.content}}></div>
                            </div>
                        </article>
                    </div>
                </section>
                <section className="s-block s-block_bottom s-block_bottom_alt last-news">
                    <div className="s-container">
                        <h2 className="s-main-header last-news__title">{translator.t('last_news')}</h2>
                        <ul className="last-news__list">
                            {
                                newsList
                            }
                        </ul>
                    </div>
                </section>
            </main>
        }
        {
            data &&
            <Footer footer={data.footer}  translator={translator} ></Footer>
        }
        </div>
    );
}