export default {
  "news": "News",
  "education": "Education",
  "profile": "Profile",
  "contacts": "Contacts",
  "autorization": "Authorization",
  "login":"Login",
  "email": "Email",
  "password": "Password (min 8 characters)",
  "confirm_password": "Confirm password",
  "type_email": "Type your email",
  "type_password": "Type your password",
  "type_name": "Type your name",
  "type_phone": "Type your phone",
  "type_comment": "Type your comment",
  "all_news": "All news",
  "read_more": "Read more",
  "address": "Adress",
  "phone": "Phone",
  "write_to_typography": "Write to the printing house",
  "client_name": "Name* ",
  "client_phone": "Account holder's phone number* ",
  "copyrights": "Sborka-3D",
  "developers": "Development and support:",
  "typogrphy": "Printing house Ultradruk",
  "adds": "World of advertising",
  "souvenir": "Souvenirs and printing",
  "forgot": "Forgot password",
  "not_robor": "I'm not robot",
  "ideas": "Print ideas",
  "employees": "For employees",
  "selected_clients": "For selected clients",
  "mass_distrubution": "Mass distribution",
  "to_leaders": "To the leaders",
  "for_partners": "For partners",
  "for_family": "Family",
  "for_friends": "Friends",
  "register": "Registration",
  "name": "Name",
  "company": "Company",
  "all": "All",
  "back_news": "Back to news",
  "back_education": "Back to Education",
  "recall": "Request a call back",
  "write_support": "Write to our support team",
  "comment": "Comment",
  "delivery": "information about delivery",
  "step": "Step",
  "required": "required",
  "must_check": "Field must be checked",
  "sent": "Sent",
  "send": "Send",
  "must_match": "Passwords must match",
  "sent_error": "Error sending",
  "autorization_error": "Autorization error",
  "last_news": "Last news",
  "logout": "Logout",
  "register_success": "Please, waite a call from administator",
  "user_exist": "User with this email already exists",
  "user_not_exists": "User with this email does not exist",
  "reset_link_sent": "A letter with a link has been sent to the mail",
  "password_reset_success": "A new password has been sent to your email",
  "password_reset_fail": "Password reset failed",
  "lorem": "Did you know that winning an election is 80% dependent on the success of the election campaign? The more advantageous the printing design and the more precisely the triggers are selected, the higher the chance to win the attention of the voter! At the same time, every candidate for deputy should know: it is allowed to produce campaign materials only in full compliance with the current legislation of Ukraine.",
  "image_reqirments" : "Image requirements",
  "image_reqirments_title" : "Image Requirements",
  "image_reqirments_description" : "You can upload pictures in PNG. The maximum size is 5 MB. Loading large images can take time.",
  "image_reqirments_link" : "Download image requirenments",
  "show_grid_top" : "Display Grid",
  "show_grid_bottom" : "1 square = 10x10mm",
  "show_grid_on" : "On",
  "show_grid_off" : "Off",
  "add_image_text" : "Add image",
  "choose_file": "Choose a file",
  "choose_product": "Choose a product",
  "add_background_color": "Add a background color",
  "template_tip": "(it displays in 3D-preview)",
  "color_tip": "* a chosen color displays in 3D preview",
  "add_text_text" : "Add text",
  "download_png" : "Download png preview",
  "print_price" : "Print the final price on a preview ",
  "price" : "Price ",
  "grn" : "grn ",
  "order" : "Create order",
  "product" : "Product",
  "show_model": "To show the model",
  "image_settings" : "Image settings",
  "tex_settings" : "Text settings",
  "change_ration" : "Scale",
  "change_angle" : "Rotation",
  "back_to_categories" : "Back to categories",
  "select_product" : "Select",
  "promo" : "Promo",
  "business" : "Busines",
  "lux" : "Lux",
  "add_size" : "Add size",
  "total_price" : "Price without printing",
  "cups" : "Cups",
  "diaries" : "Diaries",
  "umbrellas" : "Umbrellas",
  "pencils" : "Pencils",
  "pens" : "Pens",
  "flesh_drives" : "Flesh drives",
  "power_bank" : "Power bank",
  "masks" : "Masks",
  "shirts" : "Shirts",
  "order_info" : "Order information",
  "product_name" : "Title: ",
  "category" : "Category: ",
  "sub_category" : "SubCategory: ",
  "type" : "Type: ",
  "color" : "Color : ",
  "quantity" : "Quantity : ",
  "size" : "Size : ",
  "total_price_print" : "Total price: ",
  "total_price_print_grn" : " grn",
  "ceramic" : "Ceramic",
  "metal" : "Metalic",
  "order_form_title" : "Checkout form",
  "order_form_description" : "Fill in the fields below and our manager will contact you",
  "label_name" : "First name",
  "label_lastname" : "Last name",
  "label_phone" : "Phone",
  "label_address" : "Delivery address",
  "label_comment" : "Comment",
  "none" : "",
  "change_product" : "Change product?",
  "change_description" : "Progress will be lost",
  "change_yes" : "Yes",
  "change_no" : "No",
  "polygraphy" : "Polygraphy",
  "print_zones" : "Sealing zones",
  "orginal_link" : "Link to the source of the finished design",
  "news_search" : "Search articles",
  "move_text": "Move the mouse while holding down the left button",
  "zoom_text": "Rotate the mouse wheel",
  "wrong_img": "Corrupted file",
  "max_img_size": "The maximum allowed size is 5MB.",
  "order_success": "Your order has been placed. Wait for the manager's call",
  "required": "required",
  "souvenirs": "Souvenir products",
  "editor-works": "Working with the constructor",
  "seles": "Sales",
  "own-business": "Own business",
  "update_price": "Update price",
  "email_error": "must be a valid email"
}
