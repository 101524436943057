import React, { useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {Pagination,Navigation, Autoplay} from 'swiper/core';
import "swiper/components/pagination/pagination.min.css"
import config from '../config';

SwiperCore.use([Navigation, Pagination, Autoplay]);

const Slider = ({ idea })=>{

    const navigationPrevRef = React.useRef(null)
    const navigationNextRef = React.useRef(null)
    const pagination = {
        "clickable": true,
        "renderBullet": (index, className)=>{
                 return `<span className="${className}"><i></i><b></b></span>`;
        }
    }
    
     
    let slides = idea.gallery.map((gal, i)=>{
        return(
            <SwiperSlide key={i}>
                <div className="swiper-slide">
                   <div className="s-img-box idea__img-box">
                        <img className="s-img" src={config.api + gal} alt="Футболка"/>
                   </div>
                </div>
            </SwiperSlide>
        )
    })

    return(
        <div className="swiper-container js-block-slider" id="idea1">

             <Swiper pagination={pagination} navigation={true} loop={true} autoplay={true} className="mySwiper"
                    onInit={(swiper) => {
                        swiper.params.navigation.prevEl = navigationPrevRef.current;
                        swiper.params.navigation.nextEl = navigationNextRef.current;
                        swiper.navigation.init();
                        swiper.navigation.update();
                }}

              >
                    {
                        slides
                    }

                    <div className="swiper-button-prev js-idea-prev" ref={navigationPrevRef}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.71875 19.0001L3.00168 12.0001L9.71875 5.00006" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <line x1="4.26562" y1="11.9683" x2="19.9987" y2="11.9683" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                        </svg>
                    </div>
                    <div className="swiper-button-next js-idea-next" ref={navigationNextRef}>
                         <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.2812 19.0001L20.9983 12.0001L14.2813 5.00006" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                             <line x1="1" y1="-1" x2="16.7331" y2="-1" transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 20.7344 12.9683)" stroke="white" strokeWidth="2" strokeLinecap="round"/>
                        </svg>
                    </div>
                </Swiper>

                <h3 className="idea__slider-header">{ idea.title }</h3>
                {/* <p className="s-text-reg idea__slider-text">{ idea.description }</p>  */}
                <p className="s-text-reg idea__slider-text" dangerouslySetInnerHTML={{__html: idea.description }}></p> 

           </div>
    )
}

export const Ideas = ({ translator, data })=>{

    const [currentSlider, setCurrentSlider] = useState(0);

    const changeSlider = (e) =>{
        setCurrentSlider(parseInt(e.target.value));
    }


    let tabs = data.map((idea,i)=>{
        return(
            <li className={ currentSlider === i ? 'tabs__item js-tab-btn is-active': 'tabs__item js-tab-btn'} key={i}>
                <div className="tabs__icon-box">
                    <div dangerouslySetInnerHTML={{__html: idea.image }}></div>
                </div>
                <span className="tabs__text">{ idea.tab }</span>
                <button  value={i} onClick={changeSlider}></button>
            </li>
        )
    });

    let sliders = data.map((idea,i)=>{
        return(
           <div style={{ display: currentSlider === i? 'block': 'none'}} key={i} className="idea__block-slider">
               <Slider idea={idea}></Slider>
           </div>
        )
    });

    const toggleIdeaTabs = () => {
        let tabs = document.querySelector('.js-tab-list');
        
        if (window.innerWidth < 1200) {
            tabs.classList.toggle('open');
        }
    }

    return(
        <section className="s-block s-container idea">
            <div className="idea__container">
                <h2 className="s-main-header idea__title">{ translator.t('ideas')}</h2>
                <div className="idea__frame">
                    <ul className="tabs js-tab-list" onClick={toggleIdeaTabs}>
                        <svg className="tabs__arrow" width="10" height="6" viewBox="0 0 10 6" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.5299 0.528676C8.79025 0.268326 9.21236 0.268326 9.47271 0.528676C9.73306 0.789025 9.73306 1.21113 9.47271 1.47148L5.47271 5.47148C5.22032 5.72387 4.81393 5.7327 4.55082 5.49152L0.550821 1.82485C0.279408 1.57605 0.261072 1.15434 0.509868 0.88293C0.758662 0.611517 1.18037 0.593182 1.45179 0.841977L4.98125 4.07732L8.5299 0.528676Z"
                                fill="#262628"/>
                        </svg>
                        {
                            tabs
                        }
                    
                    </ul>
        
                    <div className="idea__sliders">
                        {
                            sliders
                        }
                    </div>
                </div>
            </div>
       </section>

    )
}