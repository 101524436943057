import React from 'react';
import ImageControls from '../imageControls/imageControls';
import TextControls from '../textControls/textControls';
import ProductControls from '../productControls/productControls';
import Objects from '../objects/objects';
import { ReactComponent as ToModelIcon } from '../../assets/back.svg';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './options.scss';

export default class Options extends React.Component{
    constructor(props){
        super(props);
        this.setCurrentProduct = this.setCurrentProduct.bind(this);
        this.deleteCurrentProduct = this.deleteCurrentProduct.bind(this);
        this.setOption = this.setOption.bind(this);
        this.state = { product : null };
    }
    setOption(e){
        this.props.setIndex(parseInt(e.target.value));
    }
    setCurrentProduct(product){
        this.setState({ product: product });
    }
    deleteCurrentProduct(){
        this.setState({ product: null });
    }
    render(){
        let { translator } = this.props;
        return(
            <div className="options-box">
                <div className="options">
                    <button onClick={this.setOption} value="0" className={ this.props.index === 0 ? 'active' : ''}>{ translator.t('product')}</button>
                    <button onClick={this.setOption} value="1" className={ this.props.index === 1 ? 'active' : ''}  style={{ opacity: this.props.product ? 1: 0.5}} disabled={!this.props.product}>{ translator.t('image_settings')}</button>
                    <button onClick={this.setOption} value="2" className={ this.props.index === 2 ? 'active' : ''}  style={{ opacity: this.props.product ? 1: 0.5}} disabled={!this.props.product}>{ translator.t('tex_settings')}</button>
                </div>
                <PerfectScrollbar>
                <div className={ this.props.index === 0 ? 'option-show' : 'option-hide'}>
                    <button onClick={this.setOption} value="3" className={ this.props.index === 3 ? 'show-btn active' : 'show-btn'}  id="show_model">
                        <span>{ translator.t('show_model')}</span>
                        <div className="show-btn_box">
                            <ToModelIcon></ToModelIcon>
                        </div>
                    </button>
                   <ProductControls setColor={this.props.setColor} deleteProduct={this.props.deleteProduct} translator={translator} hide_price={this.props.hide_price} updateProduct={this.props.updateProduct} product={this.state.product} setCurrentProduct={this.setCurrentProduct} deleteCurrentProduct={this.deleteCurrentProduct} canvas={this.props.canvas} setProduct={this.props.setProduct}></ProductControls>
                </div>
                {
                    this.props.index === 1 &&
                    <div className="options-container">
                        <button onClick={this.setOption} value="3" className={ this.props.index === 3 ? 'show-btn active' : 'show-btn'} id="show_model">
                            <span>{ translator.t('show_model')}</span>
                            <div className="show-btn_box">
                                <ToModelIcon></ToModelIcon>
                            </div>
                        </button>
                        <ImageControls setTemplateColor={this.props.setTemplateColor} template={this.props.template} translator={translator} objects={this.props.objects} canvas={this.props.canvas} selected={this.props.selected} clip={this.props.clip} product={this.props.product} canvasModifiedCallback={this.props.canvasModifiedCallback}></ImageControls>
                    </div>
                }{
                    this.props.index === 2 &&
                    <div className="options-container">
                        <button onClick={this.setOption} value="3" className={ this.props.index === 3 ? 'show-btn active' : 'show-btn'}  id="show_model" >
                            <span>{ translator.t('show_model')}</span>
                            <div className="show-btn_box">
                                <ToModelIcon></ToModelIcon>
                            </div>
                        </button>
                        <TextControls  template={this.props.template} addTextClick={this.props.addTextClick} canvas={this.props.canvas} selected={this.props.selected} product={this.props.product}></TextControls>
                    </div>
                }
                {
                     (this.props.index === 1 ||  this.props.index === 2) &&
                     <Objects objects={this.props.objects} canvas={this.props.canvas} selected={this.props.selected} canvasModifiedCallback={this.props.canvasModifiedCallback}></Objects>
                }
                {
                    this.props.index === 3 && ''
                }
                </PerfectScrollbar>
            </div>
        )
    }
}