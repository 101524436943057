import React, { useState, useEffect } from 'react';
import config from '../config';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {Navigation,Thumbs, Autoplay} from 'swiper/core';

SwiperCore.use([Navigation,Thumbs,Autoplay]);

export const Gallery = ({ title, gallery })=>{
    
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const navigationPrevRef = React.useRef(null)
    const navigationNextRef = React.useRef(null)
  
    let slides = gallery.images.map((image, i)=>{
        return(
            <SwiperSlide key={i}>
                  <div className="s-img-box gallery__img-box">
                     <img className="s-img" src={config.api + image} alt={'slide' + 1}/>
                 </div>
            </SwiperSlide>
        )
    });

    let slidesTh = gallery.images.map((image, i)=>{
        return(
            <SwiperSlide key={i}>
                  <div className="s-img-box gallery__thumbs-img-box">
                     <img className="s-img" src={config.api + image} alt={'slide' + 1}/>
                 </div>
            </SwiperSlide>
        )
    });

    useEffect(() => {
        if(document.body.clientWidth > 1199) {
            let swiper =  document.querySelector('.gallery__img-box');
            if(swiper){
                let heightSwiper = document.querySelector('.gallery__img-box').clientHeight;
                document.querySelector('.js-gallery-thumbs').style.height = (heightSwiper) + 'px';
    
                window.addEventListener('resize', ()=> {
                    let heightSwiper = document.querySelector('.gallery__img-box').clientHeight;
                    document.querySelector('.js-gallery-thumbs').style.height = (heightSwiper) + 'px';
                });
            }
        }
    });

    return(
        <section className="s-block s-block_bottom gallery">
            <div className="s-container">
                <h2 className="s-main-header">{title}</h2>
                <div className="gallery__swiper">
                    <Swiper className="gallery-top" onSwiper={setThumbsSwiper}
                        spaceBetween={50} loop={true} autoplay={true}
                    >
                       {
                           slides
                       }
                    </Swiper>

                    <div className="gallery__thumbs">
                       <Swiper className="gallery-thumbs js-gallery-thumbs" direction="horizontal" thumbs={{ swiper: thumbsSwiper }} 
                            spaceBetween={10} slidesPerView={3} loop={true} autoplay={true} slideToClickedSlide={true}
                           breakpoints={{1199: { direction: 'vertical', spaceBetween: 15} }}
                            onInit={(swiper) => {
                                swiper.params.navigation.prevEl = navigationPrevRef.current;
                                swiper.params.navigation.nextEl = navigationNextRef.current;
                                swiper.navigation.init();
                                swiper.navigation.update();
                            }}
                       >
                           {
                               slidesTh
                           }

                           <div className="swiper-button-next js-gallery-next" ref={navigationNextRef}>
                               <svg className="swiper-arrow" width="9" height="16" viewBox="0 0 9 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                   <path d="M1 1L7.71707 8L1 15" stroke="#262628" strokeWidth="2" strokeLinecap="round"
                                         strokeLinejoin="round"/>
                               </svg>
                           </div>
                           <div className="swiper-button-prev js-gallery-prev" ref={navigationPrevRef}>
                               <svg className="swiper-arrow" width="9" height="16" viewBox="0 0 9 16" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                   <path d="M7.71875 1L1.00168 8L7.71875 15" stroke="#262628" strokeWidth="2"
                                         strokeLinecap="round" strokeLinejoin="round"/>
                               </svg>
                           </div>
                       </Swiper>
                    </div>
                </div>
            </div>
        </section>

    )
}