import React, { useEffect } from 'react';
import { Footer } from '../footer/footer';
import Translator from '../translator/translator';
import config from '../config';
import Auth  from '../auth';
import axios from "axios";
import formatDate  from '../date';
import cutText  from '../text';
import { ReactComponent as SearchIcon } from '../assets/search2.svg';

const translator = new Translator();

export const Lessons = ()=>{
    
    const [data, setData] = React.useState(null);
    const [category, setCategory] = React.useState('null');
    const [searchText, setSearchText] = React.useState("");

    
    const onSearchTextChange = (e)=>{
        setSearchText(e.target.value);
    }

    const onChabgecategory = (e)=>{
        setCategory(e.target.value);
        fetch(e.target.value);
    }

    const fetch = (cat)=>{
        axios.get(`${config.api}site/get-lessons-page?lang=${translator.locale}&category=${cat}`)
        .then(res => {
            let responseData = res.data;
            setData(responseData);
        })
    };

    useEffect(()=>{

        fetch(category);

    },[false]);

    
    let final = [];
    if(data){
        if((searchText.length > 0)){
            data.news.forEach((n)=>{
                if(n.title.toLowerCase().indexOf(searchText.toLowerCase()) > (-1)){
                    final.push(n);
                }else if(n.content.toLowerCase().indexOf(searchText.toLowerCase()) > (-1)){
                    final.push(n);
                }
            })
       }else{
            final = data.news;
       }
     }


    let newsHtml = final.map((element, i) => {
        let date = new Date(element.createdAt);
        let dateString = formatDate(date);
        return (
                  <li className="news__item" key={i}>
                        <a className="news__link" href={`/education/${element._id}`}>
                            <div className="news__img-container">
                                <div className="s-img-box news__img-box">
                                    {
                                        element.image &&
                                        <img className="s-img" src={config.api + element.image} alt="Новость"/>
                                    }
                                </div>
                            </div>
                            <div className="news__content">
                                <p className="s-text-small s-text-small_date">{dateString}</p>
                                <p className="s-text-small s-text-small_label">{translator.t(element.category)}</p>
                                <h3 className="s-article-header news__header">{element.title}</h3>
                                <div className="s-text-reg news__text">{element.short_content}</div>
                                {
                                    i === 0 &&
                                    <button className="s-btn s-btn_wide s-btn_black news__btn" type="button" href={`/news/${element._id}`}>{translator.t('read_more')}</button>
                                }
                            </div>
                        </a>
                    </li>
        )
    });

    return(
        <div>
        {
            data && 
            <main> 
                <section className="s-block s-block_top s-block_bottom news">
                    <div className="news__search">
                        <SearchIcon className="search_icon"></SearchIcon>
                        <input className="news__search-input s-form-box__input" type="text" placeholder={ translator.t('news_search')} value={searchText} onChange={onSearchTextChange}/>
                    </div>
                    <div className="s-container news__container">
                        <h2 className="s-main-header news__title">{ translator.t('education')}</h2>
                        <div className="news__btn-box">
                            <button className={ category === 'null' ? 's-btn news__filter-btn is-active': 's-btn news__filter-btn'}  onClick={onChabgecategory} value="null">{ translator.t('all')}</button>
                            <button className={ category === 'souvenirs' ? 's-btn news__filter-btn is-active': 's-btn news__filter-btn'} onClick={onChabgecategory} value="souvenirs">{ translator.t('souvenirs')}</button>
                            <button className={ category === 'editor-works' ? 's-btn news__filter-btn is-active': 's-btn news__filter-btn'} onClick={onChabgecategory} value="editor-works">{ translator.t('editor-works')}</button>
                            {/* <button className={ category === 'seles' ? 's-btn news__filter-btn is-active': 's-btn news__filter-btn'} onClick={onChabgecategory} value="seles">{ translator.t('seles')}</button> */}
                            <button className={ category === 'own-business' ? 's-btn news__filter-btn is-active': 's-btn news__filter-btn'} onClick={onChabgecategory} value="own-business">{ translator.t('own-business')}</button>
                        </div>
                        <ul className="news__list news__list_mt">
                            {
                                newsHtml
                            }
                        </ul>
                    </div>
                </section>
               
            </main>
        }
        {
            data && Auth.isAuthenticated() &&
            <Footer footer={data.footer}  translator={translator} ></Footer>
        }
        </div>
    );
}