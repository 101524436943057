import React from 'react';
import Select from 'react-select';
import { SketchPicker  } from 'react-color';
import { ReactComponent as PenIcon } from '../../assets/color.svg';
import { ReactComponent as ArrowIcon } from '../../assets/arrow.svg';
import { ReactComponent as CenterHorisontalIcon } from '../../assets/center_horisontal.svg';
import { ReactComponent as CenterVerticalIcon } from '../../assets/center_vertical.svg';
import { ReactComponent as TextLeftIcon } from '../../assets/text_left.svg';
import { ReactComponent as TextCenterIcon } from '../../assets/text_center.svg';
import { ReactComponent as TextRightIcon } from '../../assets/text_right.svg';
import Translator from '../../translator/translator';
import AddText from './addText';
import './textControls.scss';
import {ReactComponent as CloseIcon} from "../../assets/close.svg";


const translator = new Translator();

const fonts = [
    { value: 'Arial', label: 'Arial' },
    { value: 'Bebas Neue', label: 'Bebas Neue' },
    { value: 'Bitter', label: 'Bitter' },
    { value: 'Buyan', label: 'Buyan' },
    { value: 'Futura', label: 'Futura' },
    { value: 'Gotham', label: 'Gotham' },
    { value: 'Helvetica', label: 'Helvetica' },
    { value: 'Igra', label: 'Igra' },
    { value: 'Ramona', label: 'Ramona' },
    { value: 'Roboto', label: 'Roboto' },
    { value: 'TimesNewRoman', label: 'TimesNewRoman' },
    { value: 'Wremena', label: 'Wremena' },
]

const fontsWeights = [
    { value: 'normal', label: 'normal' },
    { value: 'bold', label: 'bold' }
]

export default class TextControls extends React.Component{
    constructor(props){
        super(props);
        this.state = { text: "", font: fonts[0], fontWeight: fontsWeights[0], color: '#000000', textAlign: 'center', fontSize: 0, showPicker: false };
        this.onTextChange = this.onTextChange.bind(this);
        this.onChangeFont = this.onChangeFont.bind(this);
        this.onChangeFontWeight = this.onChangeFontWeight.bind(this);
        this.onChangeColor = this.onChangeColor.bind(this);
        this.onChangeAlign = this.onChangeAlign.bind(this);
        this.onSizeChange = this.onSizeChange.bind(this);
        this.onShowPicker = this.onShowPicker.bind(this);
        this.onOpenClick = this.onOpenClick.bind(this);
        this.state = { open: true };
        this.mirrorVertical = this.mirrorVertical.bind(this);
        this.mirrorHorisontal = this.mirrorHorisontal.bind(this);
        this.centerHorisontal = this.centerHorisontal.bind(this);
        this.centerVertical = this.centerVertical.bind(this);
    }
    centerHorisontal(){
        let selected = this.props.selected;
        let template = this.props.template.clip;
        let left = (template.left + (template.width / 2)) - (selected.width / 2);
        selected.set("left",left)
        selected.setCoords();
        this.props.canvas.renderAll();
    }
    centerVertical(){
        let selected = this.props.selected;
        let template = this.props.template.clip;
        let top = (template.top + (template.height / 2))  - (selected.height / 2);
        selected.setCoords();
        selected.set("top",top);
        this.props.canvas.renderAll();
    }
    mirrorVertical(){
        let selected = this.props.selected;
        selected.flipY = (!selected.flipY) ? true: false;
        this.props.canvas.renderAll();
    }
    mirrorHorisontal(){
        let selected = this.props.selected;
        selected.flipX = (!selected.flipX) ? true: false;
        this.props.canvas.renderAll();
    }
    onOpenClick(){
        this.setState({ open: !this.state.open });
    }
    onShowPicker(){
        this.setState({ showPicker: !this.state.showPicker });
    }
    onSizeChange(e){
        let fontSize = e.target.value;
        this.setState({ fontSize: fontSize });
        this.props.selected.set('fontSize', fontSize);
        this.props.canvas.renderAll();
    }
    onChangeAlign(align){
        this.setState({ textAlign: align });
       this.props.selected.set('textAlign',align);
        this.props.canvas.renderAll();
    };
    onChangeColor(color){
        this.setState({ color: color.hex });
        this.props.selected.set('fill', color.hex);
        this.props.canvas.renderAll();
    };
    onChangeFont(e){
        this.setState({ font: e });
        this.props.selected.set('fontFamily', e.value);
        this.props.selected.userFont = e;
        this.props.canvas.renderAll();
    }
    onChangeFontWeight(e){
        this.setState({ fontWeight: e });
        this.props.selected.fontWeight = e.value;
        this.props.selected.userFontWeight = e;
        this.props.canvas.renderAll();
    }
    onTextChange(e){
        let text = e.target.value;
        this.setState({ text: text });
        let selected = this.props.selected;
        selected.text = text;
        this.props.canvas.renderAll();
    }
    componentWillReceiveProps(nexProps){
        if(this.props.selected !== nexProps.selected){
            let selected =  nexProps.selected;
            if(selected && selected.text){
                this.setState({ text: selected.text, font: selected.userFont, fontWeight: selected.userFontWeight, color: selected.fill, textAlign: selected.textAlign });
            }
        }
    }
    componentDidMount(){
        if(this.props.selected){
            let selected =  this.props.selected;
            if(selected && selected.text){
                this.setState({ text: selected.text, font: selected.userFont, fontWeight: selected.userFontWeight, color: selected.fill, textAlign: selected.textAlign, fontSize: selected.fontSize });
            }
        }
    }
    render(){
        return(
            <div>
                {
                   this.props.product && ((!this.props.selected) || ((!this.props.selected.isText))) &&
                    <AddText addTextClick={this.props.addTextClick}></AddText>
                }
                {
                    this.props.selected && this.props.selected.isText &&
                    <div>
                        {
                            this.state.open &&
                            <div className="text_tools">
                                {/* <div className="image_controls_close-box">
                                    <div>
                                        <button className="close-btn to-editor-btn close" onClick={this.onCloseProducts}><CloseIcon></CloseIcon></button>
                                    </div>
                                </div> */}
                                <div className="text_control_top image_control_top">
                                    <div className="row">
                                        <div className="col-6">
                                            <button onClick={this.centerHorisontal}>
                                                <CenterHorisontalIcon></CenterHorisontalIcon>
                                            </button>
                                        </div>
                                        <div className="col-6">
                                            <button onClick={this.centerVertical}>
                                                <CenterVerticalIcon></CenterVerticalIcon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="text_control_top image_control_top text_align">
                                    <div className="row">
                                        <div className="col-4">
                                            <button onClick={()=>{ this.onChangeAlign('left')}}>
                                                <TextLeftIcon></TextLeftIcon>
                                            </button>
                                        </div>
                                        <div className="col-4">
                                            <button onClick={()=>{ this.onChangeAlign('center')}}>
                                                <TextCenterIcon></TextCenterIcon>
                                            </button>
                                        </div>
                                        <div className="col-4">
                                           <button onClick={()=>{ this.onChangeAlign('right')}}>
                                                <TextRightIcon></TextRightIcon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row text_control_body">
                                    <div className="col-6">
                                        <div className="textarea_wrapper">
                                            <textarea value={this.state.text} onChange={this.onTextChange}></textarea>
                                        </div>
                                        <div className="inputs">
                                            <div className="color_picker">
                                                <div className="row">
                                                    <div className="col-9">
                                                    <input type="text" value ={this.state.color} readOnly={true}></input>
                                                    </div>
                                                    <div className="col-3">
                                                        <button className="open_color" onClick={this.onShowPicker}>
                                                            <PenIcon></PenIcon>
                                                        </button>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.showPicker &&
                                                    <div className="picker_wrapper">
                                                        <SketchPicker  color={ this.state.color } onChange={this.onChangeColor}/>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                    <div className="inputs">
                                        <Select options={fonts} value={this.state.font} isSearchable={false} onChange={this.onChangeFont}/>
                                    </div>
                                    <div className="inputs">
                                        <Select options={fontsWeights} value={this.state.fontWeight} isSearchable={false} onChange={this.onChangeFontWeight}/>
                                    </div>
                                    <div className="inputs">
                                        <input type="number" value={this.state.fontSize} onChange={this.onSizeChange} placeholder={translator.t('size')}></input>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="arrow-container">
                            <button className="arrow-button" onClick={this.onOpenClick}>
                                <ArrowIcon  style={{ 'transform': this.state.open ? 'rotate(0)' : 'rotate(-180deg)'}}></ArrowIcon>
                            </button>
                        </div>
                    </div>

                }
            </div>
        )
    }
}
