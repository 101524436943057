import React from 'react';
import { Formik , Field } from "formik";
import axios from "axios";
import config from '../config';
import * as Yup from "yup";
import { ToastContainer, toast } from 'react-toastify';

export const Login = ({ translator }) => {

    return (
        <div className="login_block">
            <ToastContainer></ToastContainer>
            <div className="modal_block">
                 
                  <Formik
                        initialValues={{ email: "", password: "", not_robot: false }}
                        onSubmit={values => {
                            axios.post(`${config.api}managers/singin`, values)
                            .then(res => {
                                if (res.data) {
                                    localStorage.setItem("Token", res.data.token);
                                    localStorage.setItem('profile', JSON.stringify(res.data.profile));
                                    window.location.reload();
                                }else{
                                    toast.error(translator.t('autorization_error'), {
                                        position: toast.POSITION.TOP_RIGHT
                                    });
                                }
                            }).catch((err => {
                                toast.error(translator.t('autorization_error'), {
                                    position: toast.POSITION.TOP_RIGHT
                                });
                            }));
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string().email().required(translator.t('required')),
                            password: Yup.string().required(translator.t('required')),
                            not_robot: Yup.bool().oneOf([true], translator.t('must_check'))
                        })}
                    >
                        {props => {
                            const {
                                values,
                                touched,
                                errors,
                                handleChange,
                                handleBlur,
                                handleSubmit
                            } = props;
                            return (
                                <form onSubmit={handleSubmit}  className="s-form-box__form" >
                                    <div className="s-form-box__input-box">
                                        <label className="s-form-box__label" >{translator.t('email')}</label>
                                        <input
                                            type="text"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="email"
                                            placeholder={translator.t('type_email')}
                                            className="s-form-box__input first__input"
                                        />
                                        {errors.email && touched.email && (
                                            <div className="form_error">{errors.email}</div>
                                        )}
                                     </div>
                                     <div className="s-form-box__input-box">
                                        <label className="s-form-box__label" >{translator.t('password')}</label>
                                        <input
                                            type="password"
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="password"
                                            placeholder={translator.t('type_password')}
                                            className="s-form-box__input first__input"
                                        />
                                        {errors.password && touched.password && (
                                            <div className="form_error">{errors.password}</div>
                                        )}
                                    </div>
                                    <div className="s-form-box__add-box">
                                        <div className="s-form-box__link-box">
                                            <a className="s-text-reg s-form-box__link" href="/register">{translator.t('register')}</a>
                                            <a className="s-text-reg s-form-box__link" href="/forgot">{translator.t('forgot')}</a>
                                        </div>

                                        <label className="s-form-box__check-label">
                                            <Field type="checkbox" name="not_robot" className="s-form-box__checkbox" />
                                            <span className="s-form-box__custom-checkbox"></span>
                                            {translator.t('not_robor')}
                                        </label>
                                        {errors.not_robot && touched.not_robot && (
                                            <div className="form_error">{errors.not_robot}</div>
                                        )}
                                    </div>
                                    <div className="s-form-box__input-box">
                                        <button className="s-btn s-btn_narrow s-btn_black s-form-box__btn first__submit">{translator.t('login')}</button>
                                    </div>
   
                            </form>
                            );
                        }}
                    </Formik>
            </div>
        </div>
      
    )
}


