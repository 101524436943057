import React from 'react';
import { fabric } from 'fabric';
import ObjectID from "bson-objectid";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import { ReactComponent as ArrowTopIcon } from '../../assets/arrow-top.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/arrow-down.svg';
import { ReactComponent as CopyIcon } from '../../assets/copy.svg';
import { ReactComponent as DeleteIcon } from '../../assets/delete.svg';
import 'react-rangeslider/lib/index.css'
import './objects.scss';

function extend(object, attr) {
    object.toObject = (function(toObject) {
      return function() {
        return fabric.util.object.extend(toObject.call(this), attr);
      };
    })(object.toObject);
}

export default class Objects extends React.Component{
    constructor(props){
        super(props);
        this.onToFrontClick = this.onToFrontClick.bind(this);
        this.onToBackClick = this.onToBackClick.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.onCopyClick = this.onCopyClick.bind(this);
        this.onCopyClick = this.onCopyClick.bind(this);
        this.onSelect = this.onSelect.bind(this);
    }
    onSelect(e){
        let { objects } = this.props;
        let object = objects.filter((o)=>{ return o.id === e.target.value })[0];
        this.props.canvas.setActiveObject(object);
        this.props.canvas.renderAll();
        this.props.canvasModifiedCallback();
    }
    onSortEnd = ({oldIndex, newIndex}) => {
        let { objects } = this.props;
        let withGrid = objects.filter((o)=>{ return o.isGrid });
        let withoutGrid = objects.filter((o)=>{ return !o.isGrid });
        if(oldIndex !== newIndex){
            let child = withoutGrid[oldIndex];
            let temp = withoutGrid[newIndex];
            withoutGrid[newIndex] = child;
            withoutGrid[oldIndex] = temp;
            this.props.canvas._objects = withGrid.concat(withoutGrid);
            this.props.canvas.renderAll();
            this.props.canvasModifiedCallback();
        }
    }
    onCopyClick(e){
        let _this = this;
        this.props.canvas.getObjects().forEach((image)=>{
            if(image.id === e.target.value){
                image.clone(function(clone) {
                    let id = ObjectID().toHexString();
                    console.log(id)
                    clone.id = id;
                    if(image.isImage){
                        extend(clone, {
                            id : id,
                            isImage : true
                        });
                    }
                    if(image.isText){
                        extend(clone, {
                            id : id,
                            isText : true
                        });
                    }
                    _this.props.canvas.add(clone);
                });
            }   
         });
         this.props.canvas.renderAll();
    }
    onDeleteClick(e){
        this.props.canvas.getObjects().forEach((image)=>{
            if(image.id === e.target.value){
                this.props.canvas.remove(image);
            }
         });
         this.props.canvas.renderAll();
    }
    onToFrontClick(e){ 
        let { objects } = this.props;
        let withGrid = objects.filter((o)=>{ return o.isGrid });
        let withoutGrid = objects.filter((o)=>{ return !o.isGrid });
        let oldIndex = parseInt(e.target.value);
        if(oldIndex > 0 ){
            let newIndex = oldIndex -1;
            let child = withoutGrid[oldIndex];
            let temp = withoutGrid[newIndex];
            withoutGrid[newIndex] = child;
            withoutGrid[oldIndex] = temp;
            this.props.canvas._objects = withGrid.concat(withoutGrid);
            this.props.canvas.renderAll();
            this.props.canvasModifiedCallback();
        }
    }
    onToBackClick(e){ 
        let { objects } = this.props;
        let withGrid = objects.filter((o)=>{ return o.isGrid });
        let withoutGrid = objects.filter((o)=>{ return !o.isGrid });
        let oldIndex = parseInt(e.target.value);
        if((oldIndex) < (objects.length -1 ) ){
            let newIndex = oldIndex +1;
            let child = withoutGrid[oldIndex];
            let temp = withoutGrid[newIndex];
            withoutGrid[newIndex] = child;
            withoutGrid[oldIndex] = temp;
            this.props.canvas._objects = withGrid.concat(withoutGrid);
            this.props.canvas.renderAll();
            this.props.canvasModifiedCallback();
        }
    }
    render(){

        let { objects } = this.props;
        
        let childs = objects.filter((t)=>{ return t.isImage || t.isText});

        const SortableItem = SortableElement(({obj}) => {

            return(
                <li key={obj.id} className="object-item">
                    <div className="row d-flex align-items-center">
                        <div className="col-6">
                            {
                                obj.isImage &&
                                <div className="object-image">
                                    <img src={obj._element.src} alt="img"></img>
                                    <button onClick={this.onSelect} className="select_object" value={obj.id}></button>
                                </div>
                            }
                            {
                                obj.text &&
                                <div className="object-item-text">T
                                  <button onClick={this.onSelect} className="select_object" value={obj.id}></button>
                                </div>
                            }

                        </div>
                        <div className="col-6">
                            {
                                obj.text &&
                                <h6>{obj.text}</h6>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <div className="button_clickable">
                               <button onClick={this.onToFrontClick} value={obj.index}> </button>
                               <ArrowTopIcon ></ArrowTopIcon>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="button_clickable">
                                <button onClick={this.onToBackClick} value={obj.index}>  </button>
                                <ArrowDownIcon></ArrowDownIcon>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="button_clickable">
                                <button onClick={this.onCopyClick} value={obj.id}>
                                </button>
                                 <CopyIcon></CopyIcon>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="button_clickable">
                                <button onClick={this.onDeleteClick} value={obj.id}>
                                </button>
                                <DeleteIcon value={obj.id}></DeleteIcon>
                            </div>
                        </div>
                    </div>
                </li>
            )
        });

        const SortableList = SortableContainer(({items}) => {
            return (
                <ul className="objects-list">
                    {items.map((value, index) => {
                        value.index = index;
                        return (
                            <SortableItem key={value.id} index={index} obj={value} />
                        )
                    })}
                </ul>
            );
        });
        return(
            <div className="objects-container">
              <SortableList items={childs} onSortEnd={this.onSortEnd} />
            </div>
        )
    }
}