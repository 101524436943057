import config from '../config';
import formatDate  from '../date';
import cutText  from '../text';

export const News = ({ title, news, translator })=>{

    let newsHtml = news.map((element, i) => {
        let date = new Date(element.createdAt);
        let dateString = formatDate(date);
        return (
                  <li className="news__item" key={i}>
                        <a className="news__link" href={`/news/${element._id}`}>
                            <div className="news__img-container">
                                <div className="s-img-box news__img-box">
                                    {
                                        element.image &&
                                        <img className="s-img" src={config.api + element.image} alt="Новость"/>
                                    }
                                </div>
                            </div>
                            <div className="news__content">
                                <p className="s-text-small s-text-small_date">{dateString}</p>
                                <p className="s-text-small s-text-small_label">{translator.t(element.category)}</p>
                                <h3 className="s-article-header news__header">{element.title}</h3>
                                <div className="s-text-reg news__text" dangerouslySetInnerHTML={{__html: cutText(element.content, 500)}}></div>
                                {
                                    i === 0 &&
                                    <button className="s-btn s-btn_wide s-btn_black news__btn" type="button" href={`/news/${element._id}`}>{translator.t('read_more')}</button>
                                }
                            </div>
                        </a>
                    </li>
        )
    });

    return(
        <section className="s-block news">
            <div className="s-container">
                <h2 className="s-main-header">{title}</h2>
                <ul className="news__list">
                    {
                        newsHtml
                    }
                </ul>
                <a href="/news" className="s-btn s-btn_wide s-btn_yellow news__all-btn">{translator.t('all_news')}</a>
            </div>
        </section>

    )
}