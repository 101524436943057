import React, { useEffect } from 'react';
import { Footer } from '../footer/footer';
import { News } from './news';
import { Gallery } from './gallery';
import { Login } from './login';
import { Ideas } from './ideas';
import  Editor  from '../editor/editor';
import Translator from '../translator/translator';
import config from '../config';
import axios from "axios";
import  Auth  from '../auth';
import MainBg from '../assets/first-bg.png';
import './index.scss';

const translator = new Translator();

export const Index = ()=>{
    
    const [data, setData] = React.useState(null);

    const fetch = ()=>{
        axios.get(`${config.api}site/get-index?lang=${translator.locale}`)
        .then(res => {
            let responseData = res.data;
            setData(responseData);
        })
    };

    useEffect(()=>{

        fetch();

    },[false]);
    
    return(
        <div>
        {
            data && 
            <main> 
            <section className="first">
                <div className="first__bg" style={{ backgroundImage: data.page.image ? `url(${ config.api  + data.page.image })`: 'none'}}>

                    <div className="first__inner"></div>
                </div>
                <div className="s-container first__container">
                    <div className="first__content">
                        <h1 className="s-title">{data.page.main_title}</h1>
                        <p className="s-text-reg first__text">
                           {data.page.main_description}
                        </p>
                        <div className="first__btn-box">
                            <a className="s-btn s-btn_wide s-btn_yellow first__btn" href="#3d">{data.page.main_button_1}</a>
                            {/* <a className="s-text-small s-text-small_label s-text-small_label_alt first__link" href="#editor">{data.page.main_button_2}</a> */}
                        </div>
                    </div>

                    {
                        !Auth.isAuthenticated() &&
                        <div className="s-form-box first__form-box">
                            <h3 className="s-add-header s-add-header_text-center first__form-title">{translator.t('autorization')}</h3>
                            <Login translator={translator}></Login>
                        </div>
                    }

                </div>
            </section>
             {
                 <Ideas translator={translator} data={data.ideas}></Ideas>
             }
             {
                 <Editor own={true}></Editor>
             }
             {   data.news && 
                 <News title={data.page.news_title} news={data.news} translator={translator}></News>
             }

             {
                 <Gallery gallery={data.gallery} title={data.page.gallery_title}></Gallery>
             }

             {   
                 Auth.isAuthenticated() &&
                 <Footer footer={data.footer}  translator={translator} ></Footer>
             }

        </main>
        }
        </div>
    );
}